import { API_CASE_DELETE, API_DISCUSS_CASES_LIST } from "../../config";
import httpClient from "../../httpClient";


export const discussCases = {
    get: (pageNo, params) => {
        params = params && typeof params === 'object' ? params : {};

        const queryString = Object.entries(params)
            .filter(([key, value]) => value !== '')
            .map(([key, value]) => `${key}=${value}`)
            .join('&');

        const endpoint = API_DISCUSS_CASES_LIST + `?page=${pageNo ?? '1'}${queryString ? `&${queryString}` : ''}`;

        return httpClient.get(endpoint);
    },
};

export const deleteCase = {
    delete: (id) => httpClient.delete(API_CASE_DELETE + '/' + id),
};