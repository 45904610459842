import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useAuth } from "../../../api/services/auth";
import { logout } from "../../../api/services/logoutUser";
import { userPermissions } from "../../../enums";

const AdminNav = (props) => {
    const location = useLocation();
    const { permission } = useAuth();

    //check which route is acive for active sidebar color
    const isRouteActive = (route) => {
        return location.pathname === route;
    };

    const routes = [
        { path: "/dashboard", label: "Dashboard" },
        ...(permission?.some((p) => p.name === userPermissions.USERS_LIST)
            ? [{ path: "/user-management", label: "User Management" }]
            : []),
        ...(permission?.some((p) => p.name === userPermissions.CASES_LIST)
            ? [{ path: "/cases-directory", label: "Case Directory" }]
            : []),
        ...(permission?.some((p) => p.name === userPermissions.CASES_AUDITS_LIST)
            ? [{ path: "/case-log", label: "Case Log" }]
            : []),
        ...(permission?.some((p) => p.name === userPermissions.RESET_PASSWORDS_LIST)
            ? [{ path: "/password-management", label: "Password Management" }]
            : []),
        { path: "/", label: "Divider" },
        ...(permission?.some((p) => p.name === userPermissions.CASES_LIST)
            ? [{ path: "/draft-cases", label: "Draft Cases" }]
            : []),
        ...(permission?.some((p) => p.name === userPermissions.CASES_LIST)
            ? [{ path: "/published-cases", label: "Published Cases" }]
            : []),
        ...(permission?.some((p) => p.name === userPermissions.CASES_LIST)
            ? [{ path: "/upcoming-cases", label: "Scheduled Cases" }]
            : []),
        ...(permission?.some((p) => p.name === userPermissions.CASES_LIST)
            ? [{ path: "/pending-review", label: "Pending Review" }]
            : []),
        ...(permission?.some((p) => p.name === userPermissions.CASES_LIST)
            ? [{ path: "/discuss-cases", label: "For Discussion" }]
            : []),
        ...(permission?.some((p) => p.name === userPermissions.CASES_LIST)
            ? [{ path: "/approved-cases", label: "Approved Cases" }]
            : []),
        ...(permission?.some((p) => p.name === userPermissions.CASES_LIST)
            ? [{ path: "/archived-cases", label: "Archived Cases" }]
            : []),
        ...(permission?.some((p) => p.name === userPermissions.FLAGGED_CASES_LIST)
            ? [{ path: "/flagged-cases", label: "Flagged Cases" }]
            : []),
        { path: "/", label: "Divider" },
        ...(permission?.some((p) => p.name === userPermissions.COMORBIDITIES_LIST)
            ? [{ path: "/comorbidities", label: "Comorbidities" }]
            : []),
        ...(permission?.some((p) => p.name === userPermissions.PROCEDURES_LIST)
            ? [{ path: "/procedure", label: "Procedures" }]
            : []),
        ...(permission?.some((p) => p.name === userPermissions.COMPLICATIONS_LIST)
            ? [{ path: "/complication", label: "Complications" }]
            : []),
        ...(permission?.some((p) => p.name === userPermissions.POSITIONS_LIST)
            ? [{ path: "/position", label: "Position" }]
            : []),
        ...(permission?.some((p) => p.name === userPermissions.DEPARTMENTS_LIST)
            ? [{ path: "/departments", label: "Departments" }]
            : []),
        ...(permission?.some((p) => p.name === userPermissions.GROUPS_LIST)
            ? [{ path: "/group", label: "Group" }]
            : []),
        { path: "/", label: "Divider" },
        ...(permission?.some((p) => p.name === userPermissions.NOTIFICATIONS_LIST)
            ? [{ path: "/notifications", label: "Notifications" }]
            : []),
        ...(permission?.some((p) => p.name === userPermissions.CASES_STORE)
            ? [{ path: "/upload-case", label: "Upload Case" }]
            : []),
        ...(permission?.some((p) => p.name === userPermissions.PROFILE_UPDATE)
            ? [{ path: "/profile", label: "Profile" }]
            : []),
        { path: "/log-out", label: "Log Out", onClick: logout },
    ];

    return (
        <>
            {routes.map((route, index) => (
                <React.Fragment key={index}>
                    {route.onClick ? (
                        <li className={isRouteActive(route.path) ? "active" : ""}>
                            <a href="#" onClick={route.onClick}>
                                {route.label}
                            </a>
                        </li>
                    ) : route.label === "Divider" ? (
                        <li className="navDivider"></li>
                    ) : (
                        route.label !== "Divider" && (
                            <li className={isRouteActive(route.path) ? "active" : ""}>
                                <Link to={route.path}>{route.label}</Link>
                            </li>
                        )
                    )}
                </React.Fragment>
            ))}
        </>
    );
};

export default AdminNav;
