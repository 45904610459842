
import React, { useState, useEffect } from "react";
import { toast } from 'react-toastify';
import { HalfCircleSpinner } from 'react-epic-spinners';
import { useAuth } from "../../api/services/auth";
import { notificationListing, deleteNotification } from "../../api/services/notifications-services";
import { userPermissions } from "../../enums";
import { useNotification } from "../../context/NotificationsContext";
import Echo from 'laravel-echo';
window.Pusher = require('pusher-js');

const NotificationListing = (props) => {
    const { options } = useNotification();
    const [showDeleteMap, setShowDeleteMap] = useState({});
    const [loading, setIsLoading] = useState(true);
    const [showLoader, setShowLoader] = useState(false);
    const { permission, user } = useAuth();
    const userMessagesRef = React.useRef(props.notificationsList);

    useEffect(() => {
        if (props.notificationsList !== null) {
            userMessagesRef.current = props.notificationsList;
        }
    }, [props.notificationsList]);

    useEffect(() => {
        const echo = new Echo(options);

        console.log("echo", echo)

        //event for get notification
        echo.private(`user.${user.id}`)
            .listen('NotificationEvent', data => {
                console.log("NotificationEvent", data)
                if (!userMessagesRef.current?.some(msg => msg.id === data.id)) {
                    props.setNotificationsList([{ created_at: data.time, content: data.message, id: data.id }, ...userMessagesRef.current]);
                }
            });

        //event listen for notification delete
        echo.private(`delete-notification.${user.id}`)
            .listen('DeleteNotification', data => {
                props.setNotificationsList(prevNotificationsList => prevNotificationsList.filter(notification => notification.id !== data.id));
            });
    }, []);

    // Get_Notification_Listing
    useEffect(() => {
        notificationListing.get()
            .then((res) => {
                setIsLoading(false)
                props.setNotificationsList(res?.data);
            }
            ).catch(error => {
                setIsLoading(false)
                console.error('Error fetching data:', error)
            });
    }, []);

    // Handle_Delete_Click
    const handleDeleteClick = (id) => {
        setShowDeleteMap(prevState => ({ ...prevState, [id]: true }));
    };

    // Handle_Delete_Notification
    const handleDeleteNotification = (id) => {
        setShowLoader(true);
        deleteNotification.delete(id)
            .then((res) => {
                setShowLoader(false);
                toast.success(res.message);
                // Remove the deleted notification from the list
                props.setNotificationsList(prevNotificationsList => prevNotificationsList.filter(notification => notification.id !== id));
            }
            ).catch((error) => {
                setShowLoader(false);
                toast.error(error.data.message);
            })
            .finally(() => {
                // Reset showDelete state for the deleted notification
                setShowDeleteMap(prevState => ({ ...prevState, [id]: false }));
            });
    }


    return (
        <>
            <ul className="listUnstyled notificationList">
                {loading ? <HalfCircleSpinner
                    size={18}
                    color='black'
                    style={{
                        display: "inline-block",
                        marginLeft: "7px",
                        position: "relative",
                        top: "2px",
                    }}
                /> : <>
                    {(props.notificationsList && props.notificationsList.length > 0) ? (
                        props.notificationsList.map((item) => (
                            <li key={item.id}>
                                <article className="widgetWrap notifWidget">
                                    <div className="widgetHeader">
                                        <span>Notification Message</span>
                                        <div className="tabHeadActionWrap">
                                            Date: {item?.created_at}
                                        </div>
                                    </div>
                                    <div className="widgetFooter">
                                        <div className="notifBody">
                                            {item?.content}
                                        </div>
                                        {permission?.some(p => (p.name === userPermissions.NOTIFICATIONS_DELETE)) && (
                                            <div className="notifActions">
                                                <button
                                                    onClick={() => handleDeleteClick(item.id)}
                                                    type="button"
                                                    className="btn btnPrimary dBlock w100"
                                                >Delete Notification
                                                </button>
                                                {showDeleteMap[item.id] && (
                                                    <div className="btnWrap">
                                                        <button
                                                            type="button"
                                                            className="btn btnWhite"
                                                            onClick={() =>
                                                                setShowDeleteMap((prevState) => ({
                                                                    ...prevState,
                                                                    [item.id]: false,
                                                                }))
                                                            }
                                                        >Cancel</button>
                                                        <button
                                                            onClick={() => handleDeleteNotification(item.id)}
                                                            type="button"
                                                            className="btn btnPrimary"
                                                            disabled={showLoader}
                                                        >Delete Now
                                                            {showLoader && (
                                                                <HalfCircleSpinner
                                                                    size={18}
                                                                    style={{
                                                                        display: "inline-block",
                                                                        marginLeft: "7px",
                                                                        position: 'relative',
                                                                        top: '2px',
                                                                    }}
                                                                />
                                                            )}
                                                        </button>
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                </article>
                            </li>
                        ))
                    ) : (
                        <li className="emptyMessage">No notifications found&hellip;</li>
                    )}

                </>}

            </ul>
        </>
    );
}

export default NotificationListing;