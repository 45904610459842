import React, { useEffect, useState } from 'react'
import caseListing from '../../api/services/caseListing';
import bookmarkCasee from "../../api/services/bookmarkCase";
import { toast } from 'react-toastify';
import { Link, useNavigate } from 'react-router-dom';
import { HalfCircleSpinner } from 'react-epic-spinners';
import Popup from 'reactjs-popup';
import { useAuth } from '../../api/services/auth';
import { userPermissions, userRoles } from '../../enums';
import { sortData } from '../../utils';
import { deleteCase } from '../../api/services/cases';

function UpcommingCases() {
    const { permission, user } = useAuth()
    const navigate = useNavigate()
    const [casesList, setCasesList] = useState([]);
    const [selectedCaseBookmark, setSelectedCaseBookmark] = useState(null);
    const [showLoader, setShowLoader] = useState(false);
    const [selectedCaseId, setSelectedCaseId] = useState(null);
    const [loading, setLoading] = useState(true);
    const [isCollapse, setIsCollapse] = useState(false);
    const [deleteId, setDeleteId] = useState(null)
    const [sorting, setSorting] = useState({
        column: null,
        order: 'asc'
    });
    let oneRequest = true;

    // Get_Cases_Listing
    useEffect(() => {
        if (oneRequest) {
            oneRequest = false;
            caseListing.getCases(1, { status: 'upcoming' })
                .then((res) => {
                    setLoading(false)
                    setCasesList(res.data.map(item => ({
                        ...item,
                        status: item && item.status ? item.status.replace(/-/g, " ").replace(/\b\w/g, c => c.toUpperCase()) : "-"
                    })));
                }
                ).catch(error => {
                    setLoading(false)
                    console.error('Error fetching data:', error)
                });
        }
    }, []);

    // Handle_Bookmark_Case
    const handleBookmarkCaseClick = (caseId, bookmark) => {
        setSelectedCaseBookmark(bookmark);
        setSelectedCaseId(caseId);
        if (bookmark === 0) {
            setSelectedCaseBookmark(1);
        } else {
            setSelectedCaseBookmark(0);
        }
    };

    // Handle_Bookmark_Case
    const handleBookmarkCase = () => {
        setShowLoader(true);

        let caseId = selectedCaseId;
        let bookmark = selectedCaseBookmark;

        const requestBody = {
            bookmark: bookmark,
        };

        bookmarkCasee.bookmark(caseId, requestBody)
            .then((res) => {
                setShowLoader(false);
                toast.success(res.message);

                caseListing.getCases(1, { status: 'upcoming' })
                    .then((res) => {
                        setCasesList(res.data.map(item => ({
                            ...item,
                            status: item && item.status ? item.status.replace(/-/g, " ").replace(/\b\w/g, c => c.toUpperCase()) : "-"
                        })));
                    }
                    ).catch(error => console.error('Error fetching data:', error));
            }
            ).catch((error) => {
                setShowLoader(false);
                toast.error(error.data.message);
            }
            );
        setSelectedCaseId(null);
        setSelectedCaseBookmark(null);
    };

    // Handle_Sort_Table_Data
    const handleSort = (columnName) => {
        setSorting((prevSorting) => ({
            column: columnName,
            order: prevSorting.column === columnName && prevSorting.order === 'asc' ? 'desc' : 'asc',
        }));

        // Sort the data based on the selected column and order
        const sortedData = sortData(casesList, sorting, setSorting, columnName, sorting.order);
        setCasesList(sortedData);
    };

    const isColumnSorted = (columnName) => {
        return sorting.column === columnName;
    };

    const handleSwitch = () => {
        setIsCollapse(!isCollapse)
    }

    // Handle_Delete_CASE
    const handleDeleteCase = () => {
        setLoading(true);
        deleteCase.delete(deleteId)
            .then((res) => {
                setLoading(false);
                toast.success(res.message);
                caseListing.getCases(1, { status: 'upcoming' })
                    .then((res) => {
                        setCasesList(res.data.map(item => ({
                            ...item,
                            status: item && item.status ? item.status.replace(/-/g, " ").replace(/\b\w/g, c => c.toUpperCase()) : "-"
                        })));
                        setLoading(false)
                        setDeleteId(null)
                    }
                    ).catch(error => {
                        setLoading(false)
                        setDeleteId(null)
                        console.error('Error fetching data:', error)
                    });

            }
            ).catch((error) => {
                setLoading(false);
                setDeleteId(null)
                toast.error(error.data.message);
            });
    };
    return (
        <div className={`collapsibleWrap ${isCollapse ? 'open' : ''}`}>
            <button type="button" onClick={handleSwitch} className="collapseOppener btn btnPrimaryOutline">View Upcoming Cases</button>
            <div className="collapseContent">
                <article className="widgetWrap">
                    <div className="widgetHeader">
                        <span>Upcoming Cases (Pre-Op)</span>
                    </div>
                    <div className="tableWrap">
                        <table className="table fontSmall textCenter w100">
                            <thead>
                                <tr>
                                    <th>Ref</th>
                                    <th
                                        className={`sortable ${isColumnSorted('procedure_date') ? ' sorted' : ''}`}
                                        onClick={() => handleSort('procedure_date')}
                                    >Date <i className="icn fa-solid fa-caret-down"></i></th>
                                    <th>Consultant</th>
                                    <th>Procedure</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {(casesList && (casesList?.length > 0)) ? casesList.map((data) => (
                                    <tr key={data.id} className={`${(data.bookmark == 1) ? 'bookmarked' : ''}`}>
                                        <td data-label="Ref" className='textCapitalize'>{data?.reference ? data?.reference : "-"}</td>
                                        <td data-label="Date">{data?.procedure_date ? data?.procedure_date : '-'}</td>
                                        <td data-label="Consultant" className='textCapitalize'>{data?.consultant ? data?.consultant : '-'}</td>
                                        <td data-label="Procedure" className='textCapitalize'>{data?.procedure ? data?.procedure : '-'}</td>
                                        <td data-label="Actions">
                                            <div>
                                                {(permission?.some(p => (p.name === userPermissions.CASES_UPDATE)) && ((data.status !== 'Approved') && (data.status !== 'approved'))) && (
                                                    <button
                                                        type="button"
                                                        onClick={() => { navigate(`/update-case/${data.id}`) }}
                                                        className="actionIcn edit fa-solid fa-pencil"
                                                    ></button>
                                                )}
                                                {permission?.some(p => (p.name === userPermissions.CASES_SHOW)) && (
                                                    <button
                                                        type="button"
                                                        onClick={() => { navigate(`../case/${data.id}`) }}
                                                        className="actionIcn edit fa-solid fa-eye"
                                                    ></button>
                                                )}
                                                {permission?.some(p => (p.name === userPermissions.CASES_BOOKMARK)) && (
                                                    <button
                                                        type="button"
                                                        onClick={() => handleBookmarkCaseClick(data?.id, data?.bookmark)}
                                                        className={`actionIcn bookmarkIcn ${(data.bookmark === 0) ? 'fa-light fa-star' : 'fa-solid fa-star'}`}
                                                    ></button>
                                                )}
                                                {/* {permission?.some(p => (p.name === userPermissions.CASES_DELETE)) && (
                                                    <button
                                                        type="button"
                                                        onClick={() => setDeleteId(data.id)}
                                                        className='actionIcn edit icn fa-solid fa-folder-arrow-down'
                                                    ></button>
                                                )} */}

                                            </div>
                                        </td>
                                    </tr>
                                )) : loading ?
                                    <tr>
                                        <td colSpan={5}><HalfCircleSpinner
                                            size={18}
                                            color='black'
                                            style={{
                                                display: "inline-block",
                                                marginLeft: "7px",
                                                position: "relative",
                                                top: "2px",
                                            }}
                                        /></td>
                                    </tr> :
                                    <tr>
                                        <td colSpan={5}>No record found</td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                    </div>
                    <div className="widgetFooter">
                        <div className="textCenter">
                            <a onClick={() => { navigate('/cases-directory', { state: { upcoming: true } }) }} style={{ cursor: 'pointer' }}>View Upcoming Case Directory</a>
                        </div>
                    </div>
                </article>
            </div>

            {/* Bookmark_Case_Popup */}
            <Popup
                className="deleteUserModal"
                open={selectedCaseId !== null && selectedCaseBookmark !== null}
                onClose={() => {
                    setSelectedCaseId(null)
                    setSelectedCaseBookmark(null)
                }}
            >
                {selectedCaseId !== null && (
                    <div className="modal textCenter">
                        <p>Are you sure you want to {selectedCaseBookmark === 0 ? 'close bookmark on' : 'bookmark'}  this case?</p>
                        <div className="btnWrap">
                            <button
                                onClick={() => {
                                    setSelectedCaseId(null)
                                    setSelectedCaseBookmark(null)
                                }}
                                type="button"
                                className="btn btnSecondary"
                            >Cancel</button>
                            <button
                                onClick={handleBookmarkCase}
                                type="button"
                                className="btn btnPrimary"
                                disabled={showLoader}
                            >Confirm
                                {showLoader && (
                                    <HalfCircleSpinner
                                        size={18}
                                        style={{
                                            display: "inline-block",
                                            marginLeft: "7px",
                                            position: 'relative',
                                            top: '2px',
                                        }}
                                    />
                                )}
                            </button>
                        </div>
                    </div>
                )}
            </Popup>

            {/* DELETE_Case_Popup */}
            <Popup
                className="deleteUserModal"
                open={deleteId !== null}
                onClose={() => {
                    setDeleteId(null)
                }}
            >
                {deleteId !== null && (
                    <div className="modal textCenter">
                        <p>Are you sure you want to Archive this case?</p>
                        <div className="btnWrap">
                            <button
                                onClick={() => {
                                    setDeleteId(null)
                                }}
                                type="button"
                                className="btn btnSecondary"
                            >No</button>
                            <button
                                onClick={handleDeleteCase}
                                type="button"
                                className="btn btnPrimary"
                                disabled={showLoader}
                            >Yes
                                {showLoader && (
                                    <HalfCircleSpinner
                                        size={18}
                                        style={{
                                            display: "inline-block",
                                            marginLeft: "7px",
                                            position: 'relative',
                                            top: '2px',
                                        }}
                                    />
                                )}
                            </button>
                        </div>
                    </div>
                )}
            </Popup>
        </div>
    )
}

export default UpcommingCases