import { API_ARCHIVED_CASES_LIST } from "../../../config";
import httpClient from "../../../httpClient";

export const archivedCases = {
    get: (pageNo, params) => {
        params = params && typeof params === 'object' ? params : {};

        const queryString = Object.entries(params)
            .filter(([key, value]) => value !== '')
            .map(([key, value]) => `${key}=${value}`)
            .join('&');

        const endpoint = API_ARCHIVED_CASES_LIST + `?page=${pageNo ?? '1'}${queryString ? `&${queryString}` : ''}`;

        return httpClient.get(endpoint);
    },
};

