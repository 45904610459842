import React, { useState, useEffect } from "react";
import { useInput } from '../../hooks/useInput';
import { toast } from 'react-toastify';
import { HalfCircleSpinner } from "react-epic-spinners";
import { departmentListing, updateDepartment, recreateDepartment } from "../../api/services/departmnent-services";
import Popup from 'reactjs-popup';

const UpdateDepartment = (props) => {
    const [name, bindName] = useInput(props.selectedDepartment.name);
    const [status, setStatus] = useState(props.selectedDepartment.status);
    const [approve, setApprove] = useState(props.selectedDepartment.no_of_approve);
    const [discusss, setDiscusss] = useState(props.selectedDepartment.no_of_discuss);
    const [errors, setErrors] = useState({});
    const [showLoader, setShowLoader] = useState(false);
    const [flag, setFlag] = useState('');
    const [loader, setLoader] = useState(false);

    useEffect(() => {
        setStatus(props.selectedDepartment.status);
        setApprove(props.selectedDepartment.no_of_approve);
        setDiscusss(props.selectedDepartment.no_of_discuss);
    }, [props.selectedDepartment]);

    // Handle_Check_Validation
    const checkValidation = () => {
        let errors = {};
        let isValid = true;

        if (!name) {
            isValid = false;
            errors['name'] = 'Please enter your name.';
        }
        if (status === null || status === undefined || status === '') {
            isValid = false;
            errors['status'] = 'Please select status.';
        }
        if (!approve || approve == 0) {
            isValid = false;
            errors['approve'] = 'Please enter approve.';
        }
        if (!discusss || discusss == 0) {
            isValid = false;
            errors['discusss'] = 'Please enter approve.';
        }
        setErrors(errors);
        return isValid;
    };

    const handleUpdateDepartment = (event) => {
        event.preventDefault();

        if (checkValidation()) {
            setShowLoader(true);

            const requestBody = {
                name: name,
                status: status,
                no_of_approve: approve || 0,
                no_of_discuss: discusss || 0,
            };

            updateDepartment.update(props.selectedDepartment.id, requestBody)
                .then((res) => {
                    if (res.status === 200) {
                        toast.success(res.message);
                        window.location.reload();
                        props.handleShowUpdateDepartment();

                        departmentListing.list(1)
                            .then((res) => {
                                props.setDepartmentList([...res.data]);
                                props.setPagination(res.meta);
                            }
                        ).catch(error => toast.error("Error While Fetching The Department List"));
                    }
                }).catch((error) => {
                    setShowLoader(false);

                    if (error?.data?.errors?.id){
                        setFlag(error.data.message);

                    } else {
                        toast.error(error.data.errors.name[0]);
                    }
                }
            );
        }
    };

    // Restore_Department
    const restoreDepartmentEntity = () => {
        setLoader(true);
        
        recreateDepartment.recreate(flag)
            .then((res) => {
                if(res.status === 200){
                    toast.success(res.message);
                    setLoader(false);
                    window.location.reload();

                    departmentListing.list(1)
                        .then((res) => {
                            props.setShowCreateDepartment(false);
                            props.setDepartmentList([...res.data]);
                            props.setPagination(res.meta);
                        }
                    ).catch(error => toast.error("Error While Fetching The Department List"));
                }

            }).catch((error) => {
                setLoader(false);
                toast.error(error.data.message);
            }
        )
    }

    // Handle_Status_Select
    const handleStatusSelect = (event) => {
        setStatus(event.target.value);
    };

    return (
        <>
            <form className="userForms updateUserForm" onSubmit={(event) => handleUpdateDepartment(event)}>
                <article className="widgetWrap">
                    <div className="widgetBody">
                        <div className="dbRow">
                            <div className="dbCol dbColLg75">
                                <div className="dbRow">
                                    <div className="dbCol dbColLg50">
                                        <div className="formRow">
                                            <label className="txtLabel">Name</label>
                                            <div className="inputWrap">
                                                <input
                                                    type="text"
                                                    className={`formControl ${errors.name && 'error'}`}
                                                    {...bindName}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="dbCol dbColLg50">
                                        <div className="formRow">
                                            <label className="txtLabel">Status</label>
                                            <div className="selectWrap posRelative">
                                                <select
                                                    name="status"
                                                    className={`formControl ${errors.status && 'error'}`}
                                                    id="status"
                                                    value={status}
                                                    onChange={handleStatusSelect}
                                                >
                                                    <option value="" defaultValue>Select Status</option>
                                                    <option value="1">Active</option>
                                                    <option value="0">Inactive</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="dbCol dbColLg50">
                                        <div className="formRow">
                                            <label className="txtLabel">No of approve</label>
                                            <div className="inputWrap">
                                                <input
                                                    type="text"
                                                    className={`formControl ${errors.approve && 'error'}`}
                                                    value={approve}
                                                    onChange={(e) => {
                                                        const inputNumber = e.target.value;
                                                        if (/^[0-9]*$/.test(inputNumber)) {
                                                            setApprove(inputNumber);
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="dbCol dbColLg50">
                                        <div className="formRow">
                                            <label className="txtLabel">No of discuss</label>
                                            <div className="inputWrap">
                                                <input
                                                    type="text"
                                                    className={`formControl ${errors.discusss && 'error'}`}
                                                    value={discusss}
                                                    onChange={(e) => {
                                                        const inputNumber = e.target.value;
                                                        if (/^[0-9]*$/.test(inputNumber)) {
                                                            setDiscusss(inputNumber);
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="btnWrap">
                            <button
                                onClick={() => { props.handleShowUpdateDepartment() }}
                                type="button"
                                className="btn btnWhite"
                            >Cancel</button>
                            <button
                                type="submit"
                                className="btn btnPrimary"
                            >Update
                                {showLoader && (
                                    <HalfCircleSpinner
                                        size={18}
                                        style={{
                                            display: "inline-block",
                                            marginLeft: "7px",
                                            position: 'relative',
                                            top: '2px',
                                        }}
                                    />
                                )}
                            </button>
                        </div>
                    </div>
                </article>
            </form>
            
            {/* Restore_Department_Popup */}
            <Popup className="restoreDepartmentModal deleteUserModal" open={flag !== ''} onClose={() => setFlag('')}>
                <div className="modal textCenter">
                    <p>Department exists with the same name. Do you want to restore it?</p>
                    <div className="btnWrap">
                        <button onClick={() => setFlag('')} type="button" className="btn btnSecondary">Cancel</button>
                        <button
                            onClick={restoreDepartmentEntity}
                            type="button"
                            className="btn btnPrimary"
                            disabled={loader}
                        >Restore
                            {loader && (
                                <HalfCircleSpinner
                                    size={18}
                                    style={{
                                        display: "inline-block",
                                        marginLeft: "7px",
                                        position: 'relative',
                                        top: '2px',
                                    }}
                                />
                            )}
                        </button>
                    </div>
                </div>
            </Popup>
        </>
    )
}

export default UpdateDepartment;