import React, { useState, useEffect } from "react";
import { useAuth } from "../../api/services/auth";
import { Navigate, useNavigate } from "react-router-dom";
import { HalfCircleSpinner } from 'react-epic-spinners';
import Popup from 'reactjs-popup';
import Pagination from "../../components/pagination";
import { sortData } from "../../utils";
import { userPermissions } from "../../enums";
import CaseSearch from "../../components/case-listing/caseSearch";
import caseListing from "../../api/services/caseListing";
import { recreateCase } from "../../api/services/departmnent-services/recreate";
import { toast } from "react-toastify";
import ArchivedCaseRow from "../../components/caseItemRow/archivedCasesRow";

function ArchivedCases() {
    const navigate = useNavigate()
    const { userExist, permission } = useAuth()
    const [token, setToken] = useState(localStorage.getItem("authToken"));
    const [casesList, setCasesList] = useState([]);
    const [showFilters, setShowFilters] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const [filteredSearch, setFilteredSearch] = useState(false)
    const [selectedCaseId, setSelectedCaseId] = useState(null);
    const [pagination, setPagination] = useState({});
    const [loading, setLoading] = useState(true);
    const [formData, setFormData] = useState({
        status: 'archived',
        comorbidity_id: '',
        complication_id: '',
        procedure_id: '',
        consultant_id: '',
        flag_status: '',
    });

    const [sorting, setSorting] = useState({
        column: null,
        order: 'asc'
    });

    // Set the page Title for SEO purpose
    useEffect(() => {
        document.title = "Archived Cases | Cappagh";
    }, []);

    // Get token from LocalStorage
    useEffect(() => {
        setToken(localStorage.getItem("authToken"));

        if (token === undefined) {
            window.location.pathname = "/";
        }
    }, []);

    const fetchData = () => {
        caseListing.getCases(1, formData)
            .then((res) => {
                setCasesList(res.data.map(item => ({
                    ...item,
                    status: item && item.status ? item.status.replace(/-/g, " ").replace(/\b\w/g, c => c.toUpperCase()) : "-"
                })));
                setPagination(res.meta);
                setLoading(false)

            }
            ).catch(error => {
                setLoading(false)
                console.error('Error fetching data:', error)
            }
            );
    }

    // Get_Cases_Listing
    useEffect(() => {
        fetchData()
    }, []);

    const handleScrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth' // Smooth scrolling behavior
        });
    };

    // Hadle_Page_Click
    const handlePageClick = (pageNo) => {
        setLoading(true)
        if (filteredSearch) {

            caseListing.getCases(pageNo, formData)
                .then((res) => {
                    handleScrollToTop()
                    setCasesList([...res.data.map(item => ({
                        ...item,
                        status: item && item.status ? item.status.replace(/-/g, " ").replace(/\b\w/g, c => c.toUpperCase()) : "-"
                    }))]);
                    setPagination(res.meta);
                    setShowFilters(false);
                    setLoading(false)
                    if (res?.meta?.last_page > 1) {
                        setFilteredSearch(true)
                    } else {
                        setFilteredSearch(false)
                    }
                }
                )
                .catch(error => {
                    setLoading(false)
                    console.error('Error fetching data:', error)
                }
                );
        } else {
            caseListing.getCases(pageNo, formData)
                .then((res) => {
                    handleScrollToTop()
                    setLoading(false)
                    setCasesList(res.data.map(item => ({
                        ...item,
                        status: item && item.status ? item.status.replace(/-/g, " ").replace(/\b\w/g, c => c.toUpperCase()) : "-"
                    })));
                    setPagination(res.meta);
                }
                ).catch(error => {
                    setLoading(false)
                    console.error('Error fetching data:', error)
                }
                );
        }
    };

    // Handle_Sort_Table_Data
    const handleSort = (columnName) => {
        setSorting((prevSorting) => ({
            column: columnName,
            order: prevSorting.column === columnName && prevSorting.order === 'asc' ? 'desc' : 'asc',
        }));

        // Sort the data based on the selected column and order
        const sortedData = sortData(casesList, sorting, setSorting, columnName, sorting.order);
        setCasesList(sortedData);
    };

    const isColumnSorted = (columnName) => {
        return sorting.column === columnName;
    };

    const handlePopup = (id) => {
        setSelectedCaseId(id)
    }

    const handleRestore = () => {
        setLoading(true)
        recreateCase.recreate(selectedCaseId)
            .then((res) => {
                toast.success(res.message)
                setSelectedCaseId(null)
                setLoading(false)
                fetchData()

            })
            .catch((err) => {
                setLoading(false)
                setSelectedCaseId(null)
                toast.error(err.data.message)
            })

    }

    if (!userExist) {
        return <Navigate to="/login" />
    }

    return (
        <>
            <div className="dbContentWrap">
                {showFilters && <CaseSearch
                    setFilteredSearch={setFilteredSearch}
                    setLoading={setLoading}
                    setShowFilters={setShowFilters}
                    setCasesList={setCasesList}
                    setPagination={setPagination}
                    caseCall={'archived'}
                    formData={formData}
                    setFormData={setFormData}
                />}
                {/* Cases_Listing */}
                <div className="tableWidget">
                    <article className="widgetWrap">
                        <div className="widgetHeader">
                            <span>Archived Cases</span>
                            <div className="tabHeadActionWrap">
                                <button onClick={() => {
                                    setShowFilters(true);
                                }} type="button" className={`actionBtn ${showFilters && 'active'}`}>Search Filter <i className="fa-sharp fa-solid fa-filters"></i></button>
                            </div>
                        </div>
                        <div className="tableWrap">
                            <table className="table fontSmall textCenter w100">
                                <thead>
                                    <tr>
                                        <th>Ref</th>
                                        <th
                                            className={`sortable ${isColumnSorted('procedure_date') ? ' sorted' : ''}`}
                                            onClick={() => handleSort('procedure_date')}
                                        >Date <i className="icn fa-solid fa-caret-down"></i></th>
                                        <th>Consultant  <i className="fa-regular fa-magnifying-glass"></i></th>
                                        <th>Procedure  <i className="fa-regular fa-magnifying-glass"></i></th>
                                        <th>Comorbidities  <i className="fa-regular fa-magnifying-glass"></i></th>
                                        <th>Complications  <i className="fa-regular fa-magnifying-glass"></i></th>
                                        <th
                                            className={`sortable ${isColumnSorted('status') ? ' sorted' : ''}`}
                                            onClick={() => handleSort('status')}
                                        >Status <i className="icn fa-solid fa-caret-down"></i></th>
                                        <th>Comments</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {(casesList && (casesList?.length > 0)) ? casesList.map((data) => (
                                        <ArchivedCaseRow
                                            data={data}
                                            handlePopup={handlePopup}
                                            setCasesList={setCasesList}
                                        />
                                    )) : loading ?
                                        <tr>
                                            <td colSpan={9}><HalfCircleSpinner
                                                size={18}
                                                color='black'
                                                style={{
                                                    display: "inline-block",
                                                    marginLeft: "7px",
                                                    position: "relative",
                                                    top: "2px",
                                                }}
                                            /></td>
                                        </tr> :
                                        <tr>
                                            <td colSpan={9}>No record found</td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                        <div className="widgetFooter">
                            <div className="textCenter">
                                {pagination && (Object.keys(pagination)?.length > 0) && (
                                    <Pagination pagination={pagination} handlePageClick={handlePageClick} />
                                )}
                            </div>
                        </div>
                    </article>
                </div>
                <Popup
                    className="deleteUserModal"
                    open={selectedCaseId !== null}
                    onClose={() => {
                        setSelectedCaseId(null);
                    }}
                >
                    {selectedCaseId !== null && (
                        <div className="modal textCenter">
                            <p>Are you sure you want to Restore this case?</p>
                            <div className="btnWrap">
                                <button
                                    onClick={() => {
                                        setSelectedCaseId(null);
                                    }}
                                    type="button"
                                    className="btn btnSecondary"
                                >
                                    No
                                </button>
                                <button
                                    onClick={handleRestore}
                                    type="button"
                                    className="btn btnPrimary"
                                    disabled={showLoader}
                                >
                                    Yes
                                    {showLoader && (
                                        <HalfCircleSpinner
                                            size={18}
                                            style={{
                                                display: "inline-block",
                                                marginLeft: "7px",
                                                position: "relative",
                                                top: "2px",
                                            }}
                                        />
                                    )}
                                </button>
                            </div>
                        </div>
                    )}
                </Popup>

            </div>
        </>
    )
}

export default ArchivedCases