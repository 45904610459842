import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import Layout from "./components/layout-components/Layout";
import { useAuth } from './api/services/auth';

import Login from "./pages/login";
import Dashboard from "./pages/dashboard";
import UserManagement from "./pages/user-management";
import CaseUpload from "./pages/upload-case";
import Comorbidities from "./pages/comorbidities";
import Complication from "./pages/complications";
import Procedures from "./pages/procedures";
import Groups from "./pages/group";
import SingleCaseView from "./pages/cases/singleCaseView";
import NotFound from "./pages/404NotFound";
import PublishedCases from './pages/published-cases/index';
import DraftCases from './pages/draft-cases/index';
import UpcomingCases from './pages/upcoming-cases/index';
import FlaggedCases from './pages/flagged-cases/index';
import CaseDirectory from './pages/case-directory/index';
import UpdateCase from "./pages/update-case";
import UpdateProfile from "./pages/updateProfile";
import MyCases from "./pages/my-cases";
import Notifications from "./pages/notifications";
import DiscussCases from "./pages/discuss-cases";
import ApprovedCases from "./pages/approved-cases";
import ArchivedCases from "./pages/archived-cases";
import Departments from "./pages/department";
import CaseLogs from "./pages/case-logs";
import Position from "./pages/positions";
import PendingReview from "./pages/pending-review";
import PasswordSet from "./pages/passwords";
import SingleFlagCaseView from "./pages/singleFlagCaseView";

import { userPermissions } from "./enums";

import 'reactjs-popup/dist/index.css';
import "react-toastify/dist/ReactToastify.css";
import "../src/assets/css/main.css";
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';

function App() {
	const { userExist, permission, user } = useAuth();

	return (
		<>
			<ToastContainer
				position="bottom-right"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={true}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
			/>
			<Router>
				<Routes>
					<Route exact path="/" element={<Login />} />
					<Route path="/login" element={<Login />} />
					<Route element={userExist && <Layout />}>
						<Route path="/dashboard" element={<Dashboard />} />
						<Route path="/user-management" element={permission?.some(p => (p.name === userPermissions.USERS_LIST)) ? <UserManagement /> : <NotFound />} />
						<Route path="/upload-case" element={permission?.some(p => (p.name === userPermissions.CASES_STORE)) ? <CaseUpload /> : <NotFound />} />
						<Route path="/comorbidities" element={permission?.some(p => (p.name === userPermissions.COMORBIDITIES_LIST)) ? <Comorbidities /> : <NotFound />} />
						<Route path="/complication" element={permission?.some(p => (p.name === userPermissions.COMPLICATIONS_LIST)) ? <Complication /> : <NotFound />} />
						<Route path="/published-cases" element={permission?.some(p => (p.name === userPermissions.CASES_LIST)) ? <PublishedCases /> : <NotFound />} />
						<Route path="/draft-cases" element={permission?.some(p => (p.name === userPermissions.CASES_LIST)) ? <DraftCases /> : <NotFound />} />
						<Route path="/upcoming-cases" element={permission?.some(p => (p.name === userPermissions.CASES_LIST)) ? <UpcomingCases /> : <NotFound />} />
						<Route path="/approved-cases" element={permission?.some(p => (p.name === userPermissions.CASES_LIST)) ? <ApprovedCases /> : <NotFound />} />
						<Route path="/archived-cases" element={permission?.some(p => (p.name === userPermissions.CASES_LIST)) ? <ArchivedCases /> : <NotFound />} />
						<Route path="/cases-directory" element={permission?.some(p => (p.name === userPermissions.CASES_LIST)) ? <CaseDirectory /> : <NotFound />} />
						<Route path="/pending-review" element={permission?.some(p => (p.name === userPermissions.CASES_LIST)) ? <PendingReview /> : <NotFound />} />
						<Route path="/procedure" element={permission?.some(p => (p.name === userPermissions.PROCEDURES_LIST)) ? <Procedures /> : <NotFound />} />
						<Route path="/group" element={permission?.some(p => (p.name === userPermissions.GROUPS_LIST)) ? <Groups /> : <NotFound />} />
						<Route path="/flagged-cases" element={permission?.some(p => (p.name === userPermissions.FLAGGED_CASES_LIST)) ? <FlaggedCases /> : <NotFound />} />
						<Route path="/flagged-case/:id" element={permission?.some(p => (p.name === userPermissions.FLAGGED_CASES_LIST)) ? <SingleFlagCaseView /> : <NotFound />} />
						<Route path="/case/:id" element={permission?.some(p => (p.name === userPermissions.CASES_SHOW)) ? <SingleCaseView /> : <NotFound />} />
						<Route path="/update-case/:id" element={permission?.some(p => (p.name === userPermissions.CASES_UPDATE)) ? <UpdateCase /> : <NotFound />} />
						<Route path="/profile" element={permission?.some(p => (p.name === userPermissions.PROFILE_UPDATE)) ? <UpdateProfile /> : <NotFound />} />
						<Route path="/my-cases" element={permission?.some(p => (p.name === userPermissions.CASES_MY_CASES)) ? <MyCases /> : <NotFound />} />
						<Route path="/notifications" element={permission?.some(p => (p.name === userPermissions.NOTIFICATIONS_LIST)) ? <Notifications /> : <NotFound />} />
						<Route path="/departments" element={permission?.some(p => (p.name === userPermissions.DEPARTMENTS_LIST)) ? <Departments /> : <NotFound />} />
						<Route path="/discuss-cases" element={permission?.some(p => (p.name === userPermissions.CASES_LIST)) ? <DiscussCases /> : <NotFound />} />
						<Route path="/position" element={permission?.some(p => (p.name === userPermissions.POSITIONS_LIST)) ? <Position /> : <NotFound />} />
						<Route path="/case-log" element={permission?.some(p => (p.name === userPermissions.CASES_AUDITS_LIST)) ? <CaseLogs /> : <NotFound />} />
						<Route path="/password-management" element={permission?.some(p => (p.name === userPermissions.RESET_PASSWORDS_LIST)) ? <PasswordSet /> : <NotFound />} />
					</Route>
					<Route path="*" element={<NotFound />} />
				</Routes>
			</Router>
		</>
	);
}

export default App;
