import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import caseCommentListing from '../../api/services/caseCommentListing';
import FlagComment from './flagComment';
import { toast } from 'react-toastify';
import updateCommentInfo from '../../api/services/updateCommentInfo';
import { useAuth } from '../../api/services/auth';
import { userPermissions } from '../../enums';
import PreOpsComments from './preOpsComments';
import PostOpsComments from './postOpsCommets';
import Echo from 'laravel-echo';
import { useNotification } from '../../context/NotificationsContext';
import { HalfCircleSpinner } from 'react-epic-spinners';
window.Pusher = require('pusher-js');

function CaseComentsListing({ caseData }) {
    const { user, permission } = useAuth()
    const { options } = useNotification();
    let { id } = useParams();
    const [loading, setIsLoading] = useState(permission?.some(p => (p.name === userPermissions.COMMENTS_CASE_COMMENTS)) ? true : false);
    const [caseType, setCaseType] = useState((caseData.status == 'upcoming') ? true : false);
    const [commentData, setCommentData] = useState([]);
    const [loginInUser, setLoginInUser] = useState(localStorage.getItem("loggedInUser"));
    const [editComment, setEditComment] = useState(null);
    const [showEditModal, setShowEditModal] = useState({});
    const [upcommingCase, seUpcommingCase] = useState([]);
    const upcomingCasesRef = React.useRef(upcommingCase);
    const commentDataRef = React.useRef(commentData);

    useEffect(() => {
        const token = localStorage.getItem('authToken');
        const loggedInUser = localStorage.getItem('loggedInUser');

        if (token) {
            setLoginInUser(JSON.parse(loggedInUser));
        }
    }, []);

    useEffect(() => {
        if (permission?.some(p => (p.name === userPermissions.COMMENTS_CASE_COMMENTS))) {
            caseCommentListing.getComments(id)
                .then((res) => {
                    if (res.data) {
                        const upcommingData = res.data.filter(comment => comment.tag === 'pre-op')
                        const publishedData = res.data.filter(comment => comment.tag === 'post-op')
                        seUpcommingCase(upcommingData)
                        setCommentData(publishedData)
                        setIsLoading(false)
                    }
                }
                ).catch(error => {
                    setIsLoading(false)
                    console.error('Error fetching data:', error)
                });
        }
    }, []);

    useEffect(() => {
        if (commentData !== null) {
            commentDataRef.current = commentData;
        }
    }, [commentData]);

    useEffect(() => {
        if (upcommingCase !== null) {
            upcomingCasesRef.current = upcommingCase;
        }
    }, [upcommingCase]);

    //Event Listen
    useEffect(() => {
        const echo = new Echo(options);

        //event listen for comment added
        echo.private(`${caseData.reference}.user.${user.id}`)
            .listen('CaseCommentEvent', data => {
                if (data.tag === 'pre-op') {
                    if (!upcomingCasesRef.current?.some(msg => msg.id === data.id)) {
                        seUpcommingCase([...upcomingCasesRef.current, { id: data.id, comment: data.comment, tag: data.tag, comment_time: data.time, user_id: data.user_id, user: data.user }]);
                    }
                } else if (data.tag === 'post-op') {
                    if (!commentDataRef.current?.some(msg => msg.id === data.id)) {
                        setCommentData([...commentDataRef.current, { id: data.id, comment: data.comment, tag: data.tag, comment_time: data.time, user_id: data.user_id, user: data.user }]);
                    }
                }

            });

        //event listen for comment delete
        echo.private(`delete-comment.${user.id}`)
            .listen('DeleteComment', data => {
                seUpcommingCase(prev => prev.filter(item => item.id !== data.id));
                setCommentData(prev => prev.filter(item => item.id !== data.id));
            });

        //event listen for comment update
        echo.private(`edit-comment.${user.id}`)
            .listen('EditComment', data => {
                setCommentData(prev => prev.map(item => item.id === data.id ? { ...item, comment: data.content } : item))
                seUpcommingCase(prev => prev.map(item => item.id === data.id ? { ...item, comment: data.content } : item))
            });


    }, [])

    const openEditModal = (commentId) => {
        const commentToEdit = commentData.find((comment) => comment.id === commentId);
        setEditComment(commentToEdit);
        setShowEditModal({ [commentId]: true });
    };

    const openUpcomingCommentEditModal = (commentId) => {
        const commentToEdit = upcommingCase.find((comment) => comment.id === commentId);
        setEditComment(commentToEdit);
        setShowEditModal({ ...showEditModal, [commentId]: true });
    };

    const closeEditModal = () => {
        setEditComment(null);
        setShowEditModal((prevShowEditModal) => {
            const updatedShowEditModal = { ...prevShowEditModal };
            if (editComment && editComment.id) {
                delete updatedShowEditModal[editComment.id];
            }
            return updatedShowEditModal;
        });
    };

    const handleEditComment = (commentId) => {
        const requestBody = {
            comment: editComment.comment,
            case_id: id,
            user_id: editComment.user_id
        };

        updateCommentInfo.updateInfo(commentId, requestBody).then((res) => {
            if (res.status === 200) {
                toast.success(res.message);
                setIsLoading(false)
            }
        }
        ).catch((error) => {
            toast.error(error.data.message);
        });

        // Close the modal after editing.
        closeEditModal();
    };

    const handleShowUpcomming = () => {
        setCaseType(!caseType)
    }

    return (
        <div className="dbCol dbCol35">
            {loading ? <HalfCircleSpinner
                size={18}
                color='black'
                style={{
                    display: "inline-block",
                    marginLeft: "7px",
                    position: "relative",
                    top: "2px",
                }}
            /> :
                <>
                    {((caseData.status == 'scheduled') || (caseData.status == 'published')) && (
                        <>
                            <PreOpsComments
                                caseData={caseData}
                                upcommingCase={upcommingCase}
                                loginInUser={loginInUser}
                                showEditModal={showEditModal}
                                editComment={editComment}
                                setEditComment={setEditComment}
                                seUpcommingCase={seUpcommingCase}
                                setCommentData={setCommentData}
                                setIsLoading={setIsLoading}
                                openUpcomingCommentEditModal={openUpcomingCommentEditModal}
                                closeEditModal={closeEditModal}
                                handleEditComment={handleEditComment}
                            />
                        </>
                    )}
                    {((caseData.status !== 'scheduled') && (caseData.status !== 'published')) && (
                        <PostOpsComments
                            loginInUser={loginInUser}
                            showEditModal={showEditModal}
                            editComment={editComment}
                            setEditComment={setEditComment}
                            upcommingCase={upcommingCase}
                            seUpcommingCase={seUpcommingCase}
                            setCommentData={setCommentData}
                            setIsLoading={setIsLoading}
                            openEditModal={openEditModal}
                            closeEditModal={closeEditModal}
                            handleEditComment={handleEditComment}
                            commentData={commentData}
                            handleShowUpcomming={handleShowUpcomming}
                            caseType={caseType}
                            caseData={caseData}
                        />
                    )}
                </>
            }
            {!caseData.deleted_at && <FlagComment caseData={caseData} />}

        </div>
    )
}

export default CaseComentsListing