import React, { useEffect, useState } from 'react'
import caseFilters from '../../api/services/caseFilters';
import myCasesConsultant from '../../api/services/my-consultant-cases';
import { discussCases } from '../../api/services/cases';
import caseListing from '../../api/services/caseListing';
import flaggedCaseListing from "../../api/services/flaggedCaseListing";
import { HalfCircleSpinner } from 'react-epic-spinners';
import { archivedCases } from '../../api/services/cases/archived';

function CaseSearch({ formData, setFormData, setFilteredSearch, caseCall, setShowFilters, setCasesList, setPagination, setLoading, myCase }) {
    const [complicationList, setComplicationList] = useState([])
    const [proceduresList, setProceduresList] = useState([])
    const [comorbidityList, setComorbidityList] = useState([])
    const [consultantList, setConsultantList] = useState([])
    const [showLoader, setShowLoader] = useState(false)


    useEffect(() => {
        let stringValue = ''
        if ((caseCall === 'listing')) {
            stringValue = 'all'
        } else if (caseCall === 'published') {
            stringValue = 'published'
        } else if (caseCall === 'discuss') {
            stringValue = 'for-discussion'
        } else if (caseCall === 'draft') {
            stringValue = 'draft'
        } else if (caseCall === 'upcoming') {
            stringValue = 'scheduled'
        } else if (caseCall === 'flagged') {
            stringValue = 'flagged'
        } else if (caseCall === 'approved') {
            stringValue = 'approved'
        } else if (caseCall === 'archived') {
            stringValue = 'archived'
        } else if (caseCall === 'pending-review') {
            stringValue = 'pending-review'
        } else if (caseCall === 'publishedCase') {
            stringValue = 'current'
        } else if (caseCall === 'upcomingCase') {
            stringValue = 'upcoming'
        } else if (caseCall === 'discussed') {
            stringValue = 'discussed'
        } else if (myCase === 'myCase') {
            stringValue = 'my-cases'
        }

        caseFilters.getfilters(stringValue)
            .then((res) => {
                setComplicationList([...res.data.complications]);
                setProceduresList([...res.data.procedures]);
                setComorbidityList([...res.data.comorbidites]);
                setConsultantList([...res.data.consultants]);
            }
            ).catch(error => console.error('Error fetching data:', error));
    }, [])

    const handleSearchChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSearch = () => {
        setLoading(true)
        setShowLoader(true)
        if (caseCall === 'listing' ||
            caseCall === 'published' ||
            caseCall === 'draft' ||
            caseCall === 'upcoming' ||
            caseCall === 'pending-review' ||
            caseCall === 'approved' ||
            caseCall === 'archived'
        ) {
            caseListing.getCases(1, formData)
                .then((res) => {
                    setCasesList([...res.data.map(item => ({
                        ...item,
                        status: item && item.status ? item.status.replace(/-/g, " ").replace(/\b\w/g, c => c.toUpperCase()) : "-"
                    }))]);
                    setPagination(res?.meta);
                    setLoading(false)
                    setShowLoader(false)
                    if (res?.meta?.last_page > 1) {
                        setFilteredSearch(true)
                    } else {
                        setFilteredSearch(false)
                    }
                }
                )
                .catch(error => {
                    setLoading(false)
                    console.error('Error fetching data:', error)
                }
                );
        } else if (caseCall === 'publishedCase') {
            caseListing.getCases(1, { ...formData, status: 'current' })
                .then((res) => {
                    setCasesList([...res.data.map(item => ({
                        ...item,
                        status: item && item.status ? item.status.replace(/-/g, " ").replace(/\b\w/g, c => c.toUpperCase()) : "-"
                    }))]);
                    setPagination(res?.meta);
                    setLoading(false)
                    setShowLoader(false)
                    if (res?.meta?.last_page > 1) {
                        setFilteredSearch(true)
                    } else {
                        setFilteredSearch(false)
                    }
                }
                )
                .catch(error => {
                    setLoading(false)
                    console.error('Error fetching data:', error)
                }
                );

        } else if (caseCall === 'upcomingCase') {
            caseListing.getCases(1, { ...formData, status: 'upcoming' })
                .then((res) => {
                    setCasesList([...res.data.map(item => ({
                        ...item,
                        status: item && item.status ? item.status.replace(/-/g, " ").replace(/\b\w/g, c => c.toUpperCase()) : "-"
                    }))]);
                    setPagination(res?.meta);
                    setLoading(false)
                    setShowLoader(false)
                    if (res?.meta?.last_page > 1) {
                        setFilteredSearch(true)
                    } else {
                        setFilteredSearch(false)
                    }
                }
                )
                .catch(error => {
                    setLoading(false)
                    console.error('Error fetching data:', error)
                }
                );

        } else if (caseCall === 'discussed') {
            caseListing.getCases(1, { ...formData, status: 'discussed' })
                .then((res) => {
                    setCasesList([...res.data.map(item => ({
                        ...item,
                        status: item && item.status ? item.status.replace(/-/g, " ").replace(/\b\w/g, c => c.toUpperCase()) : "-"
                    }))]);
                    setPagination(res?.meta);
                    setLoading(false)
                    setShowLoader(false)
                    if (res?.meta?.last_page > 1) {
                        setFilteredSearch(true)
                    } else {
                        setFilteredSearch(false)
                    }
                }
                )
                .catch(error => {
                    setLoading(false)
                    console.error('Error fetching data:', error)
                }
                );

        } else if (caseCall === 'discuss') {
            caseListing.getCases(1, { ...formData, status: 'for-discussion' })
                .then((res) => {
                    setCasesList([...res.data.map(item => ({
                        ...item,
                        status: item && item.status ? item.status.replace(/-/g, " ").replace(/\b\w/g, c => c.toUpperCase()) : "-"
                    }))]);
                    setPagination(res?.meta);
                    setLoading(false)
                    setShowLoader(false)
                    if (res?.meta?.last_page > 1) {
                        setFilteredSearch(true)
                    } else {
                        setFilteredSearch(false)
                    }
                }
                )
                .catch(error => {
                    setLoading(false)
                    console.error('Error fetching data:', error)
                }
                );

        } else if (myCase === 'myCase') {
            myCasesConsultant.get(1, formData)
                .then((res) => {
                    setCasesList([...res.data.map(item => ({
                        ...item,
                        status: item && item.status ? item.status.replace(/-/g, " ").replace(/\b\w/g, c => c.toUpperCase()) : "-"
                    }))]);
                    setPagination(res?.meta);
                    setLoading(false)
                    setShowLoader(false)
                    if (res?.meta?.last_page > 1) {
                        setFilteredSearch(true)
                    } else {
                        setFilteredSearch(false)
                    }
                }
                )
                .catch(error => {
                    setLoading(false)
                    console.error('Error fetching data:', error)
                }
                );
        } else if (caseCall === 'archived') {
            archivedCases.get(1, formData)
                .then((res) => {
                    setCasesList([...res.data.map(item => ({
                        ...item,
                        status: item && item.status ? item.status.replace(/-/g, " ").replace(/\b\w/g, c => c.toUpperCase()) : "-"
                    }))]);
                    setPagination(res?.meta);
                    setLoading(false)
                    setShowLoader(false)
                    if (res?.meta?.last_page > 1) {
                        setFilteredSearch(true)
                    } else {
                        setFilteredSearch(false)
                    }
                }
                )
                .catch(error => {
                    setLoading(false)
                    console.error('Error fetching data:', error)
                }
                );
        } else if (caseCall === 'flagged') {
            flaggedCaseListing.getCases(1, formData)
                .then((res) => {
                    setCasesList([...res.data.map(item => ({
                        ...item,
                        status: item && item.status ? item.status.replace(/-/g, " ").replace(/\b\w/g, c => c.toUpperCase()) : "-"
                    }))]);
                    setPagination(res?.meta);
                    setLoading(false)
                    setShowLoader(false)
                    if (res?.meta?.last_page > 1) {
                        setFilteredSearch(true)
                    } else {
                        setFilteredSearch(false)
                    }
                }
                )
                .catch(error => {
                    setLoading(false)
                    console.error('Error fetching data:', error)
                }
                );
        }
    };

    return (
        <form className="userForms seachForm">
            <article className="widgetWrap">
                <div className="widgetBody">
                    <div className="dbRow">
                        <div className="dbCol dbColLg33">
                            <div className="formRow">
                                <label className="txtLabel">Comorbidities</label>
                                <div className="selectWrap posRelative">
                                    <select
                                        name="comorbidity_id"
                                        id="comorbidities"
                                        className="formControl"
                                        value={formData.comorbidity_id}
                                        onChange={handleSearchChange}
                                    >
                                        <option value="" >Select Comorbidities</option>
                                        {comorbidityList.map((item) => (
                                            <option key={item.id} value={item.id}>
                                                {item.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="dbCol dbColLg33">
                            <div className="formRow">
                                <label className="txtLabel">Complications</label>
                                <div className="selectWrap posRelative">
                                    <select
                                        name="complication_id"
                                        id="complications"
                                        className="formControl"
                                        value={formData.complication_id}
                                        onChange={handleSearchChange}
                                    >
                                        <option value="" >Select Complication</option>
                                        {complicationList.map((item) => (
                                            <option key={item.id} value={item.id}>
                                                {item.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="dbCol dbColLg33">
                            <div className="formRow">
                                <label className="txtLabel">Procedure</label>
                                <div className="selectWrap posRelative">
                                    <select
                                        name="procedure_id"
                                        id="procedure"
                                        className="formControl"
                                        value={formData.procedure_id}
                                        onChange={handleSearchChange}
                                    >
                                        <option value="" >Select Procedure</option>
                                        {proceduresList?.map((item) => (
                                            <option key={item.id} value={item.id}>
                                                {item.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        </div>
                        {!myCase && (
                            <div className="dbCol dbColLg33">
                                <div className="formRow">
                                    <label className="txtLabel">Consultant</label>
                                    <div className="selectWrap posRelative">
                                        <select
                                            name="consultant_id"
                                            id="consultant_id"
                                            className="formControl"
                                            value={formData.consultant_id}
                                            onChange={handleSearchChange}
                                        >
                                            <option value="" >Select Consultant</option>
                                            {consultantList?.map((item) => (
                                                <option key={item.id} value={item.id}>
                                                    {item.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </div>
                        )}
                        {caseCall === 'flagged' && (
                            <div className="dbCol dbColLg33">
                                <div className="formRow">
                                    <label className="txtLabel">Status</label>
                                    <div className="selectWrap posRelative">
                                        <select
                                            name="flag_status"
                                            id="flag_status"
                                            className="formControl"
                                            defaultValue={formData.flag_status}
                                            onChange={handleSearchChange}
                                        >
                                            <option value="">Select Status</option>
                                            <option value="0">Open Flagged</option>
                                            <option value="1">Close Flagged</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="btnWrap">
                        <button
                            onClick={() => { setShowFilters(false) }}
                            type="button"
                            className="btn btnWhite"
                        >Cancel</button>
                        <button type="button"
                            onClick={handleSearch}
                            className="btn btnPrimary"
                            disabled={showLoader}
                        >Search
                            {showLoader && (
                                <HalfCircleSpinner
                                    size={18}
                                    style={{
                                        display: "inline-block",
                                        marginLeft: "7px",
                                        position: 'relative',
                                        top: '2px',
                                    }}
                                />
                            )}
                        </button>
                    </div>
                </div>
            </article>
        </form>
    )
}

export default CaseSearch