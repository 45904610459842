import httpClient from "../../httpClient";
import {
    API_DEPARTMENT_LISTING,
    API_DEPARTMENT_CREATE,
    API_DEPARTMENT_UPDATE,
    API_DEPARTMENT_DELETE,
    API_DEPARTMENT_SHOW,
    API_DEPARTMENT_USER,
    API_DEPARTMENT_USER_HAVE_CASES,
    API_DEPARTMENT_ALL_LISTING,
    API_USERS_DEPARTMENT_CAESES,
    API_DEPARTMENT_GROUP,
    API_GROUP_CONSULTANT,
    API_DEPARTMENT_RECREATE,
    API_PROCEDURE_RECREATE,
    API_DEPARTMENT_FILTER,
} from "../../config";

export const departmentListing = {
    list: (pageNo, params) => {
        params = params && typeof params === "object" ? params : {};

        const queryString = Object.entries(params)
            .filter(([key, value]) => value !== "")
            .map(([key, value]) => `${key}=${value}`)
            .join("&");

        const endpoint =
            API_DEPARTMENT_LISTING +
            `?page=${pageNo ?? "1"}${queryString ? `&${queryString}` : ""}`;

        return httpClient.get(endpoint);
    },
};

export const createDepartment = {
    create: (data) => httpClient.post(API_DEPARTMENT_CREATE, data),
};

export const departmentFilter = {
    get: () => httpClient.get(API_DEPARTMENT_FILTER),
};

export const updateDepartment = {
    update: (id, data) => httpClient.post(API_DEPARTMENT_UPDATE + "/" + id, data),
};

export const deleteDepartment = {
    delete: (id) => httpClient.delete(API_DEPARTMENT_DELETE + "/" + id),
};

export const showDepartment = {
    show: (id) => httpClient.get(API_DEPARTMENT_SHOW + "/" + id),
};

export const userDepartmentListHaveCases = {
    get: (id) => httpClient.get(API_DEPARTMENT_USER_HAVE_CASES + "/" + id),
};

export const departmentGroups = {
    get: (id, params) => {
        params = params && typeof params === "object" ? params : {};

        const queryString = Object.entries(params)
            .filter(([key, value]) => value !== "")
            .map(([key, value]) => `${key}=${value}`)
            .join("&");

        const endpoint =
            API_DEPARTMENT_GROUP +
            "/" +
            id +
            `${queryString ? "?" + queryString : ""}`;
        return httpClient.get(endpoint);
    },
};

export const departmentAllUser = {
    get: (id, params) => {
        params = params && typeof params === "object" ? params : {};

        const queryString = Object.entries(params)
            .filter(([key, value]) => value !== "")
            .map(([key, value]) => `${key}=${value}`)
            .join("&");

        const endpoint =
            API_DEPARTMENT_USER +
            "/" +
            id +
            `?${queryString ? `&${queryString}` : ""}`;
        return httpClient.get(endpoint);
    },
};

export const userDepartmentCases = {
    get: (department, selectedUserId, params) => {
        params = params && typeof params === "object" ? params : {};

        const queryString = Object.entries(params)
            .filter(([key, value]) => value !== "")
            .map(([key, value]) => `${key}=${value}`)
            .join("&");
        const endpoint =
            API_USERS_DEPARTMENT_CAESES +
            `/${department}/${selectedUserId}` +
            `?${queryString}`;
        return httpClient.get(endpoint);
    },
};

export const departmentListingAll = {
    list: () => httpClient.get(API_DEPARTMENT_ALL_LISTING),
};

export const groupConsultant = {
    get: (id) => httpClient.get(API_GROUP_CONSULTANT + "/" + id),
};

export const recreateDepartment = {
    recreate: (id) => httpClient.get(API_DEPARTMENT_RECREATE + "/" + id),
};
