import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { useNotification } from '../../context/NotificationsContext';
import { useAuth } from '../../api/services/auth';
import Echo from "laravel-echo";
import { userPermissions } from '../../enums';
window.Pusher = require("pusher-js");

function ArchivedCaseRow({ setCasesList, data, handlePopup }) {

    const navigate = useNavigate()
    const { options } = useNotification();
    const { permission, user } = useAuth()

    useEffect(() => {
        const echo = new Echo(options);
        echo.private(`${data.reference}.user.${user.id}`)
            .listen("CaseCommentEvent", (data) => {
                setCasesList((prevCasesList) =>
                    prevCasesList.map((item) => {
                        if (data.case_id === item.id) {
                            return { ...item, unread_count: 1, total_comments: (item.total_comments + 1) };
                        } else {
                            return item;
                        }
                    })
                );
            })
    }, []);


    return (
        <tr key={data.id} className={`${(data.bookmark == 1) ? 'bookmarked' : ''}`}>
            <td data-label="Ref">{data?.reference || '-'}</td>
            <td data-label="Date">{data?.procedure_date || '-'}</td>
            <td data-label="Consultant">{data?.consultant || '-'}</td>
            <td data-label="Procedure">{data?.procedure || '-'}</td>
            <td data-label="Comorbidities" data-text-alignment="left">{data.comorbidities ? Object.values(data?.comorbidities).join(', ') : '-'}</td>
            <td data-label="Complications" data-text-alignment="left">{data.complications ? Object.values(data?.complications).join(', ') : '-'}</td>
            <td data-label="Status" className="txtCaptilize">{data?.status || '-'}</td>
            <td data-label="Comments">
                {data?.total_comments}
                {(data?.unread_count > 0) &&
                    <span className="badgeNew">New<i className="icn fa-solid fa-message"></i></span>
                }
            </td>
            <td data-label="Actions">
                <div>
                    {permission?.some(p => (p.name === userPermissions.CASES_SHOW)) && (
                        <button
                            type="button"
                            onClick={() => { navigate(`../case/${data.id}`) }}
                            className="actionIcn edit fa-solid fa-eye"
                        ></button>
                    )}
                    {permission?.some(p => (p.name === userPermissions.CASES_RECREATE)) && (
                        <button
                            type="button"
                            onClick={() => { handlePopup(data.id) }}
                            className="actionIcn fa-sharp fa-solid fa-trash-can-undo"
                        ></button>
                    )}
                </div>
            </td>
        </tr>
    )
}

export default ArchivedCaseRow