import React, { useEffect, useState } from 'react'
import { sortData } from '../../utils';
import Popup from 'reactjs-popup';
import { HalfCircleSpinner } from 'react-epic-spinners';
import { toast } from 'react-toastify';
import { useAuth } from '../../api/services/auth';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import bookmarkCasee from "../../api/services/bookmarkCase";
import { userPermissions, userRoles } from '../../enums';
import { deleteCase } from '../../api/services/cases';
import caseListing from '../../api/services/caseListing';
import Pagination from '../../components/pagination';
import CaseSearch from '../../components/case-listing/caseSearch';
import DiscussionCaseRow from '../../components/caseItemRow/DiscussionCaseRow';
import DiscussedCaseRow from '../../components/caseItemRow/DiscussedCaseRow';

function DiscussCases() {
    const { permission, user } = useAuth();
    const navigate = useNavigate()
    const location = useLocation()
    const [active, setActive] = useState(location?.state?.upcoming ? true : false);
    const [discussionCases, setDiscussionCases] = useState([]);
    const [disscussionCases, setDisscussionCases] = useState([]);
    const [selectedCaseBookmark, setSelectedCaseBookmark] = useState(null);
    const [showLoader, setShowLoader] = useState(false);
    const [selectedCaseId, setSelectedCaseId] = useState(null);
    const [discussedLoading, setDiscussedLoading] = useState(true);
    const [disscussionLoading, setDisscussionLoading] = useState(true);
    const [bookmarkCaseType, setBookmarkCaseType] = useState('')
    const [filteredSearch, setFilteredSearch] = useState(false)
    const [showFilters, setShowFilters] = useState(false);
    const [pagination, setPagination] = useState({});
    const [disscussionPagination, setDisscussionPagination] = useState({});
    const [deleteId, setDeleteId] = useState(null)
    const [disscussionDeleteId, setDisscussionDeleteId] = useState(null)
    const [formData, setFormData] = useState({
        comorbidity_id: '',
        complication_id: '',
        procedure_id: '',
        consultant_id: '',
        flag_status: '',
    });
    const [discussedSorting, setDiscussedSorting] = useState({
        column: null,
        order: 'asc'
    });
    const [discussionSorting, setDiscussionSorting] = useState({
        column: null,
        order: 'asc'
    });

    // Get_Discussed_Cases_Listing
    useEffect(() => {
        caseListing.getCases(1, { status: 'for-discussion' })
            .then((res) => {
                setDiscussedLoading(false)
                setDiscussionCases(res.data.map(item => ({
                    ...item,
                    status: item && item.status ? item.status.replace(/-/g, " ").replace(/\b\w/g, c => c.toUpperCase()) : "-"
                })));
                setPagination({ ...res.meta });
            }
            ).catch(error => {
                setDiscussedLoading(false)
                console.error('Error fetching data:', error)
            });

        caseListing.getCases(1, { status: 'discussed' })
            .then((res) => {
                setDisscussionCases(res.data.map(item => ({
                    ...item,
                    status: item && item.status ? item.status.replace(/-/g, " ").replace(/\b\w/g, c => c.toUpperCase()) : "-"
                })));
                setDisscussionPagination({ ...res.meta });
                setDisscussionLoading(false)
            }
            ).catch(error => {
                setDisscussionLoading(false)
                console.error('Error fetching data:', error)
            });
    }, []);

    const handleScrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth' // Smooth scrolling behavior
        });
    };

    // Handle_Bookmark_Click
    const handleBookmarkCaseClick = (caseId, bookmark, caseType) => {
        setBookmarkCaseType(caseType)
        setSelectedCaseBookmark(bookmark);
        setSelectedCaseId(caseId);
        if (bookmark === 0) {
            setSelectedCaseBookmark(1);
        } else {
            setSelectedCaseBookmark(0);
        }
    };

    // Handle_Bookmark_Case
    const handleBookmarkCase = () => {
        setShowLoader(true);

        let caseId = selectedCaseId;
        let bookmark = selectedCaseBookmark;

        const requestBody = {
            bookmark: bookmark,
        };

        bookmarkCasee.bookmark(caseId, requestBody)
            .then((res) => {
                setShowLoader(false);
                toast.success(res.message);
                if (bookmarkCaseType === 'discussed') {
                    caseListing.getCases(1, { status: 'for-discussion' })
                        .then((res) => {
                            setDiscussionCases(res.data);
                            setPagination({ ...res.meta });
                        }
                        ).catch(error => {
                            console.error('Error fetching data:', error)
                        });

                } else if (bookmarkCaseType === 'discussion') {
                    caseListing.getCases(1, { status: 'discussed' })
                        .then((res) => {
                            setDisscussionCases(res.data);
                            setDisscussionPagination({ ...res.meta });
                        }
                        ).catch(error => {
                            console.error('Error fetching data:', error)
                        });
                }
            }
            ).catch((error) => {
                setShowLoader(false);
                toast.error(error.data.message);
            }
            );
        setSelectedCaseId(null);
        setSelectedCaseBookmark(null);
        setBookmarkCaseType('')
    };

    // Handle_Sort_Table_Data
    const handleDiscussedSort = (columnName) => {
        setDiscussedSorting((prevSorting) => ({
            column: columnName,
            order: prevSorting.column === columnName && prevSorting.order === 'asc' ? 'desc' : 'asc',
        }));

        // Sort the data based on the selected column and order
        const sortedData = sortData(discussionCases, discussedSorting, setDiscussedSorting, columnName, discussedSorting.order);
        setDiscussionCases(sortedData);
    };

    const isDiscussedColumnSorted = (columnName) => {
        return discussedSorting.column === columnName;
    };

    // Hadle_Page_Click
    const handlePageClick = (pageNo) => {
        caseListing.getCases(pageNo, { status: 'for-discussion' })
            .then((res) => {
                handleScrollToTop()
                setDiscussedLoading(false)
                setDiscussionCases(res.data);
                setPagination({ ...res.meta });
            }
            ).catch(error => {
                setDiscussedLoading(false)
                console.error('Error fetching data:', error)
            });
    };

    // Hadle_Page_Click
    const handleDisscussionPageClick = (pageNo) => {
        caseListing.getCases(pageNo, { status: 'discussed' })
            .then((res) => {
                handleScrollToTop()
                setDisscussionCases(res.data);
                setDisscussionPagination({ ...res.meta });
                setDisscussionLoading(false)
            }
            ).catch(error => {
                setDisscussionLoading(false)
                console.error('Error fetching data:', error)
            });
    };

    // Handle_Sort_Table_Data
    const handleDisscussionSort = (columnName) => {
        setDiscussionSorting((prevSorting) => ({
            column: columnName,
            order: prevSorting.column === columnName && prevSorting.order === 'asc' ? 'desc' : 'asc',
        }));

        // Sort the data based on the selected column and order
        const sortedData = sortData(disscussionCases, discussionSorting, setDiscussionSorting, columnName, discussionSorting.order);
        setDisscussionCases(sortedData);
    };

    const isDisscussionColumnSorted = (columnName) => {
        return discussionSorting.column === columnName;
    };

    const handleSwitch = (status) => {
        setShowFilters(false);
        setFormData({
            comorbidity_id: '',
            complication_id: '',
            procedure_id: '',
            consultant_id: '',
            flag_status: '',
        })
        if (status !== 'active') {
            setActive(false)
            caseListing.getCases(1, { status: 'for-discussion' })
                .then((res) => {
                    setDiscussedLoading(false)
                    setDiscussionCases(res.data.map(item => ({
                        ...item,
                        status: item && item.status ? item.status.replace(/-/g, " ").replace(/\b\w/g, c => c.toUpperCase()) : "-"
                    })));
                    setPagination({ ...res.meta });
                }
                ).catch(error => {
                    setDiscussedLoading(false)
                    console.error('Error fetching data:', error)
                });

        } else {
            setActive(true)
            caseListing.getCases(1, { status: 'discussed' })
                .then((res) => {
                    setDisscussionCases(res.data.map(item => ({
                        ...item,
                        status: item && item.status ? item.status.replace(/-/g, " ").replace(/\b\w/g, c => c.toUpperCase()) : "-"
                    })));
                    setDisscussionPagination({ ...res.meta });
                    setDisscussionLoading(false)
                }
                ).catch(error => {
                    setDisscussionLoading(false)
                    console.error('Error fetching data:', error)
                });
        }
    }

    // Handle_Delete_CASE
    const handlDiscussedDeleteCase = () => {
        deleteCase.delete(deleteId)
            .then((res) => {
                toast.success(res.message);
                caseListing.getCases(1, { status: 'for-discussion' })
                    .then((res) => {
                        setDiscussedLoading(false)
                        setDeleteId(null)
                        setDisscussionDeleteId(null)
                        setDiscussionCases(res.data.map(item => ({
                            ...item,
                            status: item && item.status ? item.status.replace(/-/g, " ").replace(/\b\w/g, c => c.toUpperCase()) : "-"
                        })));
                        setPagination({ ...res.meta });
                    }
                    ).catch(error => {
                        setDiscussedLoading(false)
                        console.error('Error fetching data:', error)
                    });

            }
            ).catch((error) => {
                setDeleteId(null)
                setDisscussionDeleteId(null)
                toast.error(error.data.message);
            });
    };

    const handlDisscussionDeleteCase = () => {
        deleteCase.delete(disscussionDeleteId)
            .then((res) => {
                toast.success(res.message);
                caseListing.getCases(1, { status: 'discussed' })
                    .then((res) => {
                        setDisscussionCases(res.data.map(item => ({
                            ...item,
                            status: item && item.status ? item.status.replace(/-/g, " ").replace(/\b\w/g, c => c.toUpperCase()) : "-"
                        })));
                        setDisscussionPagination({ ...res.meta });
                        setDisscussionLoading(false)
                        setDeleteId(null)
                        setDisscussionDeleteId(null)
                    }
                    ).catch(error => {
                        setDisscussionLoading(false)
                        console.error('Error fetching data:', error)
                    });

            }
            ).catch((error) => {
                setDeleteId(null)
                setDisscussionDeleteId(null)
                toast.error(error.data.message);
            });
    };

    return (
        <div>
            <div className="dbContentWrap">
                {showFilters && <CaseSearch
                    setFilteredSearch={setFilteredSearch}
                    setLoading={!active ? setDiscussedLoading : setDisscussionLoading}
                    caseCall={active ? 'discussed' : 'discuss'}
                    setShowFilters={setShowFilters}
                    setCasesList={!active ? setDiscussionCases : setDisscussionCases}
                    formData={formData}
                    setFormData={setFormData}
                    setPagination={!active ? setPagination : setDisscussionPagination}
                />}
                <div className="tableWidget">
                    <ul className="navTabs listUnstyled">
                        <li onClick={() => {
                            handleSwitch('inActive')

                        }} className={!active ? "active" : ""}><a>For Discussion </a></li>
                        <li onClick={() => {
                            handleSwitch('active')

                        }} className={active ? "active" : ""}><a >Discussed</a></li>
                    </ul>
                    <div className="tabsContentWrap">
                        {!active && (
                            <div id="tab1" className="tabContent">
                                <article className="widgetWrap">
                                    <div className="widgetHeader">
                                        <span>Group Cases</span>
                                        <div className="tabHeadActionWrap">
                                            <button onClick={() => {
                                                setShowFilters(true);
                                            }} type="button" className={`actionBtn ${showFilters && 'active'}`}>Search Filter <i className="fa-sharp fa-solid fa-filters"></i></button>
                                        </div>
                                    </div>
                                    <div className="tableWrap">
                                        <table className="table fontSmall textCenter w100">
                                            <thead>
                                                <tr>
                                                    <th
                                                        className={`sortable ${isDiscussedColumnSorted('reference') ? ' sorted' : ''}`}
                                                        onClick={() => handleDiscussedSort('reference')}
                                                    >Ref <i className="icn fa-solid fa-caret-down"></i></th>
                                                    <th
                                                        className={`sortable ${isDiscussedColumnSorted('procedure_date') ? ' sorted' : ''}`}
                                                        onClick={() => handleDiscussedSort('procedure_date')}
                                                    >Date <i className="icn fa-solid fa-caret-down"></i></th>
                                                    <th>Consultant <i className="fa-regular fa-magnifying-glass"></i></th>
                                                    <th>Procedure <i className="fa-regular fa-magnifying-glass"></i></th>
                                                    <th>Comorbidities <i className="fa-regular fa-magnifying-glass"></i></th>
                                                    <th>Complications <i className="fa-regular fa-magnifying-glass"></i></th>
                                                    <th
                                                        className={`sortable ${isDiscussedColumnSorted('status') ? ' sorted' : ''}`}
                                                        onClick={() => handleDiscussedSort('status')}
                                                    >Status <i className="icn fa-solid fa-caret-down"></i></th>
                                                    <th>Comments</th>
                                                    <th>Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {(discussionCases && (discussionCases?.length > 0)) ? discussionCases.map((data) => (
                                                    <DiscussedCaseRow
                                                        data={data}
                                                        handleBookmarkCaseClick={handleBookmarkCaseClick}
                                                        setDiscussionCases={setDiscussionCases}
                                                        setDeleteId={setDeleteId}
                                                    />
                                                )) : discussedLoading ?
                                                    <tr>
                                                        <td colSpan={9}><HalfCircleSpinner
                                                            size={18}
                                                            color='black'
                                                            style={{
                                                                display: "inline-block",
                                                                marginLeft: "7px",
                                                                position: "relative",
                                                                top: "2px",
                                                            }}
                                                        /></td>
                                                    </tr> :
                                                    <tr>
                                                        <td colSpan={9}>No record found</td>
                                                    </tr>
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                    {/* <div className="widgetFooter">
                                        <div className="textCenter">
                                            <Link to="/published-cases">View More</Link>
                                        </div>
                                    </div> */}
                                    <div className="widgetFooter">
                                        <div className="textCenter">
                                            {pagination && (Object.keys(pagination)?.length > 0) && (
                                                <Pagination pagination={pagination} handlePageClick={handlePageClick} />
                                            )}
                                        </div>
                                    </div>
                                </article>

                            </div>
                        )}

                        {active && (
                            <div id="tab2" className="tabContent">
                                <article className="widgetWrap">
                                    <div className="widgetHeader">
                                        <span>Group Cases</span>
                                        <div className="tabHeadActionWrap">
                                            <button onClick={() => {
                                                setShowFilters(true);
                                            }} type="button" className={`actionBtn ${showFilters && 'active'}`}>Search Filter <i className="fa-sharp fa-solid fa-filters"></i></button>
                                        </div>
                                    </div>
                                    <div className="tableWrap">
                                        <table className="table fontSmall textCenter w100">
                                            <thead>
                                                <tr>
                                                    <th
                                                        className={`sortable ${isDisscussionColumnSorted('reference') ? ' sorted' : ''}`}
                                                        onClick={() => handleDisscussionSort('reference')}
                                                    >Ref <i className="icn fa-solid fa-caret-down"></i></th>
                                                    <th
                                                        className={`sortable ${isDisscussionColumnSorted('procedure_date') ? ' sorted' : ''}`}
                                                        onClick={() => handleDisscussionSort('procedure_date')}
                                                    >Date <i className="icn fa-solid fa-caret-down"></i></th>
                                                    <th>Consultant <i className="fa-regular fa-magnifying-glass"></i></th>
                                                    <th>Procedure <i className="fa-regular fa-magnifying-glass"></i></th>
                                                    <th>Comorbidities <i className="fa-regular fa-magnifying-glass"></i></th>
                                                    <th>Complications <i className="fa-regular fa-magnifying-glass"></i></th>
                                                    <th
                                                        className={`sortable ${isDisscussionColumnSorted('status') ? ' sorted' : ''}`}
                                                        onClick={() => handleDisscussionSort('status')}
                                                    >Status <i className="icn fa-solid fa-caret-down"></i></th>
                                                    <th>Comments</th>
                                                    <th>Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {(disscussionCases && (disscussionCases?.length > 0)) ? disscussionCases.map((data) => (
                                                    <DiscussionCaseRow
                                                        data={data}
                                                        handleBookmarkCaseClick={handleBookmarkCaseClick}
                                                        setDisscussionCases={setDisscussionCases}
                                                        setDisscussionDeleteId={setDisscussionDeleteId}
                                                    />
                                                )) : disscussionLoading ?
                                                    <tr>
                                                        <td colSpan={9}><HalfCircleSpinner
                                                            size={18}
                                                            color='black'
                                                            style={{
                                                                display: "inline-block",
                                                                marginLeft: "7px",
                                                                position: "relative",
                                                                top: "2px",
                                                            }}
                                                        /></td>
                                                    </tr> :
                                                    <tr>
                                                        <td colSpan={9}>No record found</td>
                                                    </tr>
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="widgetFooter">
                                        <div className="textCenter">
                                            {disscussionPagination && (Object.keys(disscussionPagination)?.length > 0) && (
                                                <Pagination pagination={disscussionPagination} handlePageClick={handleDisscussionPageClick} />
                                            )}
                                        </div>
                                    </div>
                                    {/* <div className="widgetFooter">
                                        <div className="textCenter">
                                            <Link to="/upcoming-cases">View More</Link>
                                        </div>
                                    </div> */}
                                </article>
                            </div>
                        )}
                    </div>
                </div>
            </div>

            {/* Bookmark_Case_Popup */}
            <Popup
                className="deleteUserModal"
                open={selectedCaseId !== null && selectedCaseBookmark !== null}
                onClose={() => {
                    setSelectedCaseId(null)
                    setSelectedCaseBookmark(null)
                }}
            >
                {selectedCaseId !== null && (
                    <div className="modal textCenter">
                        <p>Are you sure you want to {selectedCaseBookmark === 0 ? 'close bookmark on' : 'bookmark'}  this case?</p>
                        <div className="btnWrap">
                            <button
                                onClick={() => {
                                    setSelectedCaseId(null)
                                    setSelectedCaseBookmark(null)
                                    setBookmarkCaseType('')
                                }}
                                type="button"
                                className="btn btnSecondary"
                            >Cancel</button>
                            <button
                                onClick={handleBookmarkCase}
                                type="button"
                                className="btn btnPrimary"
                                disabled={showLoader}
                            >Confirm
                                {showLoader && (
                                    <HalfCircleSpinner
                                        size={18}
                                        style={{
                                            display: "inline-block",
                                            marginLeft: "7px",
                                            position: 'relative',
                                            top: '2px',
                                        }}
                                    />
                                )}
                            </button>
                        </div>
                    </div>
                )}
            </Popup>

            {/* DELETE_Case_Popup */}
            <Popup
                className="deleteUserModal"
                open={deleteId !== null}
                onClose={() => {
                    setDeleteId(null)
                    setDisscussionDeleteId(null)
                }}
            >
                {deleteId !== null && (
                    <div className="modal textCenter">
                        <p>Are you sure you want to Archive this case?</p>
                        <div className="btnWrap">
                            <button
                                onClick={() => {
                                    setDeleteId(null)
                                    setDisscussionDeleteId(null)
                                }}
                                type="button"
                                className="btn btnSecondary"
                            >No</button>
                            <button
                                onClick={handlDiscussedDeleteCase}
                                type="button"
                                className="btn btnPrimary"
                                disabled={showLoader}
                            >Yes
                                {showLoader && (
                                    <HalfCircleSpinner
                                        size={18}
                                        style={{
                                            display: "inline-block",
                                            marginLeft: "7px",
                                            position: 'relative',
                                            top: '2px',
                                        }}
                                    />
                                )}
                            </button>
                        </div>
                    </div>
                )}
            </Popup>

            {/* DELETE_Discussion_Case_Popup */}
            <Popup
                className="deleteUserModal"
                open={disscussionDeleteId !== null}
                onClose={() => {
                    setDeleteId(null)
                    setDisscussionDeleteId(null)
                }}
            >
                {disscussionDeleteId !== null && (
                    <div className="modal textCenter">
                        <p>Are you sure you want to Archive this case?</p>
                        <div className="btnWrap">
                            <button
                                onClick={() => {
                                    setDeleteId(null)
                                    setDisscussionDeleteId(null)
                                }}
                                type="button"
                                className="btn btnSecondary"
                            >No</button>
                            <button
                                onClick={handlDisscussionDeleteCase}
                                type="button"
                                className="btn btnPrimary"
                                disabled={showLoader}
                            >Yes
                                {showLoader && (
                                    <HalfCircleSpinner
                                        size={18}
                                        style={{
                                            display: "inline-block",
                                            marginLeft: "7px",
                                            position: 'relative',
                                            top: '2px',
                                        }}
                                    />
                                )}
                            </button>
                        </div>
                    </div>
                )}
            </Popup>
        </div>
    )
}

export default DiscussCases