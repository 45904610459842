import httpClient from "../httpClient";
import { API_FLAGGED_CASE_LISTING } from "../config";

const flaggedCaseListing = {
  getCases: (pageNo, params) => {
    params = params && typeof params === 'object' ? params : {};

    const queryString = Object.entries(params)
      .filter(([key, value]) => value !== '')
      .map(([key, value]) => `${key}=${value}`)
      .join('&');

    const endpoint = API_FLAGGED_CASE_LISTING + `?page=${pageNo ?? '1'}${queryString ? `&${queryString}` : ''}`;

    return httpClient.get(endpoint);
  },
};

export default flaggedCaseListing;
