import httpClient from "../httpClient";
import { API_GROUP_LISTING } from "../config";

const groupListing = {
    getGroups: (pageNo, params) => {
        params = params && typeof params === 'object' ? params : {};

        const queryString = Object.entries(params)
            .filter(([key, value]) => value !== '')
            .map(([key, value]) => `${key}=${value}`)
            .join('&');

        const endpoint = API_GROUP_LISTING + `?page=${pageNo ?? '1'}${queryString ? `&${queryString}` : ''}`;

        return httpClient.get(endpoint);
    },
};

export default groupListing;
