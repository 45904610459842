import React, { createContext, useState, useContext } from 'react';
const NotificationsContext = createContext({});

export const NotificationProvider = ({ children }) => {
    const notificationCount = localStorage.getItem('notificationCount');
    const [notificationsCount, setNotificationsCount] = useState(notificationCount)
    const token = localStorage.getItem('authToken');


    const options = {
        broadcaster: process.env.REACT_APP_PUSHER_BROADCASTER,
        key: process.env.REACT_APP_PUSHER_KEY,
        cluster: process.env.REACT_APP_PUSHER_CLUSTER,
        forceTLS: true,
        useTLS: true,
        wsHost: process.env.REACT_APP_PUSHER_HOST,
        wssHost: process.env.REACT_APP_PUSHER_HOST,
        wsPort: process.env.REACT_APP_PUSHER_PORT,
        wssPort: process.env.REACT_APP_PUSHER_PORT,
        withCredentials: true,
        authEndpoint: `${process.env.REACT_APP_PUSHER_AUTH_URL}/broadcasting/auth`,
        auth: {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        },
        encrypted: true,
        disableStats: true,
        enabledTransports: ['ws', 'wss'],
    };

    console.log("options echo", options)

    return (
        <NotificationsContext.Provider
            value={{
                notificationsCount,
                setNotificationsCount,
                options
            }}
        >
            {children}
        </NotificationsContext.Provider>
    );
};

export const useNotification = () => useContext(NotificationsContext);
