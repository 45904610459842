import React, { useState, useEffect } from "react";
import { useInput } from '../../hooks/useInput';
import createUser from "../../api/services/createUser";
import { positionListingAll } from "../../api/services/positions/index";
import { toast } from 'react-toastify';
import { HalfCircleSpinner } from 'react-epic-spinners';
import Select from 'react-select';
import { useAllDepartments } from "../../context/allDepartmentContext";
import { departmentGroups } from "../../api/services/departmnent-services";
import { recreateUser } from "../../api/services/departmnent-services/recreate";
import Popup from "reactjs-popup";

const CreateUser = (props) => {
    const [password, bindPassword, resetPassword] = useInput("");
    const [confirmPassword, bindConfirmPassword, resetConfirmPassword] = useInput("");
    const [username, bindUsername, resetUsername] = useInput("");
    const [salutationName, setSalutationName] = useState("");
    const [firstname, bindFirstName, resetFirstname] = useInput("");
    const [department, setDepartment] = useState(null);
    const [position, setPosition] = useState("");
    const [userType, setUserType] = useState("");
    const [surname, bindSurname, resetSurname] = useInput("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [email, bindEmail, resetEmail] = useInput("");
    const [selectedImage, setSelectedImage] = useState('');
    const [errors, setErrors] = useState({});
    const [showLoader, setShowLoader] = useState(false);
    const [positionListing, setPositionListing] = useState([]);
    const [group, setGroup] = useState([]);
    const [groupListing, setGroupListing] = useState([]);
    const [status, setStatus] = useState("1");
    const { departmentListing } = useAllDepartments();
    const [departmentEntitiesList, setDepartmentEntitiesList] = useState([]);
    const [loader, setLoader] = useState(false);
    const [flag, setFlag] = useState("");
    const [showPass, setShowPass] = useState(false);
    const [showConfirmPass, setShowConfirmPass] = useState(false);

    const transformDepartmentsData = departmentListing.map(item => ({
        id: item.value,
        name: item.label,
    }));

    // Handle_File_Upload
    const handleFileChange = (e) => {
        const file = e.target.files[0];

        if (file) {
            const fileSizeLimit = 3 * 1024 * 1024; // 5 MB
            const allowedFileTypes = ['image/jpeg', 'image/png', 'image/svg+xml'];

            if (file.size > fileSizeLimit) {
                alert('File size exceeds the limit of 3MB');
                return;
            }

            if (!allowedFileTypes.includes(file.type)) {
                alert('Invalid file type. Please select a JPG, PNG, or SVG file.');
                return;
            }

            setSelectedImage(file);
        }
    };

    // Check_Validation_Function
    const checkValidation = () => {
        let errors = {};
        let isValid = true;
        if (!username) {
            isValid = false;
            errors['username'] = 'Please enter your Username.';
        }
        if (!department) {
            isValid = false;
            errors['department'] = 'Please select a Department.';
        }
        if (!group || (group?.length < 1)) {
            isValid = false;
            errors['group'] = 'Please select a group.';
        }
        if (!firstname) {
            isValid = false;
            errors['firstname'] = 'Please enter your Firstname.';
        }
        if (!salutationName) {
            isValid = false;
            errors['salutationName'] = 'Please select a Salutation.';
        }
        if (!position) {
            isValid = false;
            errors['position'] = 'Please select a Position.';
        }
        if (!surname) {
            isValid = false;
            errors['surname'] = 'Please enter your Surname.';
        }

        if (!phoneNumber) {
            isValid = false;
            errors['number'] = 'Please enter your Number.';
        }
        if (!email) {
            isValid = false;
            errors['email'] = 'Please enter your Email.';
        }
        if (!userType) {
            isValid = false;
            errors['userType'] = 'Please select user type.';
        }
        if (!password) {
            isValid = false;
            errors["password"] = "Please enter password.";
        }
        if (!confirmPassword) {
            isValid = false;
            errors["confirmPassword"] = "Please enter Confirm Password.";
        }
        if (password !== confirmPassword) {
            isValid = false;
            errors["confirmPassword"] =
                "Password and Confirm Password should be same.";
            toast.error("Password and Confirm Password should be same.");
        } else if (password && password?.length < 8) {
            isValid = false;
            toast.error("Pasword length should be atleast 8 chracters");
        }
        // if (email) {
        //     var pattern = new RegExp(
        //         /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
        //     );
        //     if (!pattern.test(email)) {
        //         isValid = false;
        //         errors["email"] = "Please enter valid email address.";
        //     }
        // }
        setErrors(errors);
        return isValid;
    };

    // Fetch_Positions_Listing_onload
    useEffect(() => {
        positionListingAll.get()
            .then((res) => {
                setPositionListing(res.data);
            }
            ).catch(error => console.error('Error fetching data:', error));
    }, []);

    // Handle_Position_Select
    const handlePositionSelect = (event) => {
        setPosition(event.target.value);
    };

    const handleUserTypeSelect = (event) => {
        setUserType(event.target.value);
    };

    const handleSalutationName = (event) => {
        setSalutationName(event.target.value);
    };

    // Handle_Group_Select
    const handleGroupSelect = (event) => {
        setGroup(event);
    };

    // Handle_Create_User
    const handleCreateUser = (event) => {
        event.preventDefault();

        if (checkValidation()) {
            setShowLoader(true);

            const requestBody = new FormData();
            requestBody.append('username', username);
            requestBody.append('first_name', firstname);
            requestBody.append('salutation', salutationName);
            requestBody.append('surname', surname);
            requestBody.append('email', email);
            requestBody.append('position_id', position);
            requestBody.append('phone_number', phoneNumber);
            selectedImage && requestBody.append('image', selectedImage);
            requestBody.append('department_id', department);
            requestBody.append('password', password);
            requestBody.append('confirm_password', confirmPassword);
            group?.length > 0 && group.forEach((group, index) => {
                requestBody.append(`group_ids[${index}]`, group.value);
            });
            requestBody.append('status', status);
            requestBody.append('type', userType);

            createUser.create((requestBody))
                .then((res) => {
                    if (res.status === 200) {
                        setShowLoader(false);
                        props.setShowCreateUser(false)
                        toast.success(res.message);
                        window.location.reload();
                    }
                }
                ).catch((error) => {
                    setShowLoader(false);
                    if (error?.data?.errors?.id) {
                        setFlag(error.data.message);
                    } else {
                        toast.error(error?.message || error?.data?.message);
                    }
                }
                );
        }
    };

    // Handle_Status_Select
    const handleStatusSelect = (event) => {
        setStatus(event.target.value);
    };

    // MultiSelect_Style_Object
    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            boxShadow: 'none',
            borderColor: state.isFocused ? '#aaa' : provided.borderColor,
            height: '30px',
            cursor: 'pointer',
            paddingRight: '24px',
            borderRadius: '0',
        }),
        option: (styles) => {
            return {
                ...styles,
                fontSize: "14px",
                transition: "all 0.35s ease",
                cursor: 'pointer',
                textAlign: 'left'
            };
        },
        indicatorsContainer: (provided) => ({
            ...provided,
            display: 'none',
        }),
        placeholder: (styles) => ({
            ...styles,
            color: 'var(--black)',
            fontSize: "14px",
        }),
    };

    // Handle_Department_Select
    const handleDepartmentSelect = (event) => {
        setGroup([])
        const selectedDepartmentId = event.target.value;
        setDepartment(selectedDepartmentId);

        if (event.target.value) {
            fetchDepartmentEntitiesList(event.target.value);
        }
    };

    // Fetch_Selected_Department_Entities_Listing
    const fetchDepartmentEntitiesList = (id) => {
        departmentGroups.get(id)
            .then((res) => {
                setDepartmentEntitiesList(res.data);
                if (res.data?.length) {
                    const transformedGroupData = res.data.map(item => ({
                        value: item.id,
                        label: item.name,
                    }));
                    setGroupListing(transformedGroupData);
                }
            }
            ).catch(error => {
                console.error('Error fetching data:', error);
            }
            );
    }

    // Restore_User
    const restorUserEntity = () => {
        setLoader(true);
        recreateUser
            .recreate(flag)
            .then((res) => {
                if (res.status === 200) {
                    toast.success(res.message);
                    setLoader(false);
                    window.location.reload();
                }
            })
            .catch((error) => {
                setLoader(false);
                toast.error(error.data.message);
            });
    };

    // Handle_Show_Password
    const handleShowPass = () => {
        setShowPass(!showPass);
    }
    // Handle_Show_Confirm_Password
    const handleShowConfirmPass = () => {
        setShowConfirmPass(!showConfirmPass);
    }

    return (
        <>
            <form className="loginForm userForms updateUserForm" onSubmit={(event) => handleCreateUser(event)}>
                <article className="widgetWrap">
                    <div className="widgetBody">
                        <div className="dbRow">
                            <div className="dbCol dbColLg75">
                                <div className="dbRow">
                                    <div className="dbCol dbColLg50">
                                        <div className="formRow">
                                            <label className="txtLabel">Username</label>
                                            <div className="inputWrap">
                                                <input
                                                    type="text"
                                                    className={`formControl ${errors.username && 'error'}`}
                                                    {...bindUsername}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="dbCol dbColLg50">
                                        <div className="formRow">
                                            <label className="txtLabel">Type</label>
                                            <div className="selectWrap posRelative">
                                                <select
                                                    name="type"
                                                    className={`formControl ${errors.userType && 'error'}`}
                                                    id="type"
                                                    value={userType}
                                                    onChange={handleUserTypeSelect}
                                                >
                                                    <option value="" defaultValue>Select User Type</option>
                                                    <option value='assistant'>Editor </option>
                                                    <option value='consultant'>Reviewer </option>
                                                    <option value='hod'>Clinical Oversight</option>
                                                    <option value='super-user'>Super Admin</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="dbCol dbColLg50">
                                        <div className="formRow">
                                            <label className="txtLabel">Salutation</label>
                                            <div className="selectWrap posRelative">
                                                <select
                                                    name="type"
                                                    className={`formControl ${errors.salutationName && 'error'}`}
                                                    id="type"
                                                    value={salutationName}
                                                    onChange={handleSalutationName}
                                                >
                                                    <option value="" defaultValue>Select Salutation</option>
                                                    <option value='miss'>Miss </option>
                                                    <option value='mr'>Mr </option>
                                                    <option value='mrs'>Mrs</option>
                                                    <option value='ms'>Ms</option>
                                                    <option value='dr'>Dr</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="dbCol dbColLg50">
                                        <div className="formRow">
                                            <label className="txtLabel">First Name</label>
                                            <div className="inputWrap">
                                                <input
                                                    type="text"
                                                    className={`formControl ${errors.firstname && 'error'}`}
                                                    {...bindFirstName}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="dbCol dbColLg50">
                                        <div className="formRow">
                                            <label className="txtLabel">Surname</label>
                                            <div className="inputWrap">
                                                <input
                                                    type="text"
                                                    className={`formControl ${errors.surname && 'error'}`}
                                                    {...bindSurname}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="dbCol dbColLg50">
                                        <div className="formRow">
                                            <label className="txtLabel">Email</label>
                                            <div className="inputWrap">
                                                <input
                                                    type="email"
                                                    className={`formControl ${errors.email && 'error'}`}
                                                    {...bindEmail}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="dbCol dbColLg50">
                                        <div className="formRow">
                                            <label className="txtLabel">Contact Info</label>
                                            <div className="inputWrap">
                                                <input
                                                    type="text"
                                                    className={`formControl ${errors.number && 'error'}`}
                                                    value={phoneNumber}
                                                    onChange={(e) => {
                                                        const inputNumber = e.target.value;
                                                        if (/^[0-9]*$/.test(inputNumber)) {
                                                            setPhoneNumber(inputNumber);
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="dbCol dbColLg50">
                                        <div className="formRow">
                                            <label className="txtLabel">Department</label>
                                            <div className="selectWrap posRelative">
                                                <select
                                                    name="group"
                                                    className={`formControl ${errors.department && 'error'}`}
                                                    id="department"
                                                    defaultValue={department}
                                                    onChange={handleDepartmentSelect}
                                                >
                                                    <option value="" defaultValue>Select Department</option>
                                                    {transformDepartmentsData.map((item) => (
                                                        <option key={item.id} value={item.id}>
                                                            {item.name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            {/* <div className="selectWrap posRelative">
                                            <Select
                                                name="department"
                                                className={`basic-multi-select multiSelectDropdown txtRight ${errors.department && 'error'}`}
                                                id="department"
                                                defaultValue={department}
                                                options={departmentListing}
                                                onChange={(event) => {
                                                    handleDepartmentSelect(event)
                                                }}
                                                classNamePrefix="select"
                                                isMulti
                                                styles={customStyles}
                                                placeholder="Select Department"
                                            />
                                        </div> */}
                                        </div>
                                    </div>
                                    <div className="dbCol dbColLg50">
                                        <div className="formRow">
                                            <label className="txtLabel">Group</label>
                                            <div className="selectWrap posRelative">
                                                <Select
                                                    name="group"
                                                    className={`basic-multi-select multiSelectDropdown txtRight ${errors.group && 'error'}`}
                                                    id="group"
                                                    value={group}
                                                    options={groupListing}
                                                    onChange={(event) => {
                                                        handleGroupSelect(event)
                                                    }}
                                                    classNamePrefix="select"
                                                    isMulti
                                                    styles={customStyles}
                                                    placeholder="Select Groups"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="dbCol dbColLg50">
                                        <div className="formRow">
                                            <label className="txtLabel">Position</label>
                                            <div className="selectWrap posRelative">
                                                <select
                                                    name="position"
                                                    className={`formControl ${errors.position && 'error'}`}
                                                    id="position"
                                                    defaultValue={position}
                                                    onChange={handlePositionSelect}
                                                >
                                                    <option value="" defaultValue>Select Position</option>
                                                    {positionListing.map((item) => (
                                                        <option key={item.id} value={item.id}>
                                                            {item.name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="dbCol dbColLg50">
                                        <div className="formRow">
                                            <label className="txtLabel">Status</label>
                                            <div className="selectWrap posRelative">
                                                <select
                                                    name="status"
                                                    className={`formControl`}
                                                    id="status"
                                                    defaultValue={status}
                                                    onChange={handleStatusSelect}
                                                >
                                                    <option value="" defaultValue>Select Status</option>
                                                    <option value="1">Active</option>
                                                    <option value="0">Inactive</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="dbCol dbColLg50">
                                        <div className="formRow">
                                            <label className="txtLabel"></label>
                                            <div className="inputWrap">
                                                <label htmlFor="profileImage" className="imageUploadBtn">Upload Image</label>
                                                <input
                                                    type="file"
                                                    name="profile-image"
                                                    id="profileImage"
                                                    onChange={handleFileChange}
                                                    className="srOnly"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="dbCol dbColLg50">
                                        <div className="formRow">
                                            <label className="txtLabel">Password</label>
                                            <div className={`inputWrap posRelative passWrap ${showPass && 'view'}`}>
                                                <input
                                                    type={showPass ? 'text' : 'password'}
                                                    className={`formControl ${errors.password && "error"}`}
                                                    id="passwordInput"
                                                    {...bindPassword}
                                                />
                                                <button
                                                    type='button'
                                                    className={`icnView fa-solid posAbsolute ${showPass ? 'fa-eye-slash' : 'fa-eye'}`}
                                                    onClick={() => {
                                                        handleShowPass()
                                                    }}
                                                ></button>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="dbCol dbColLg50">
                                        <div className="formRow">
                                            <label className="txtLabel">Confirm Password</label>
                                            <div className={`inputWrap posRelative passWrap ${showConfirmPass && 'view'}`}>
                                                <input
                                                    id="passwordInput"
                                                    type={showConfirmPass ? 'text' : 'password'}
                                                    className={`formControl ${errors.confirmPassword && "error"
                                                        }`}
                                                    {...bindConfirmPassword}
                                                />
                                                <button
                                                    type='button'
                                                    className={`icnView fa-solid posAbsolute ${showConfirmPass ? 'fa-eye-slash' : 'fa-eye'}`}
                                                    onClick={() => {
                                                        handleShowConfirmPass()
                                                    }}
                                                ></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="dbCol dbColLg25">
                                <div className="imgWrap selectedImage">
                                    {selectedImage ?
                                        <img src={URL.createObjectURL(selectedImage)} alt="Uploaded Image" className="uploaded-image" />
                                        :
                                        <div className="placeholderImage">?</div>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="btnWrap">
                            <button
                                onClick={() => { props.setShowCreateUser(false) }}
                                type="button"
                                className="btn btnWhite"
                            >Cancel</button>
                            <button
                                type="submit"
                                className="btn btnPrimary mb0"
                                disabled={showLoader}
                            >Create User
                                {showLoader && (
                                    <HalfCircleSpinner
                                        size={18}
                                        style={{
                                            display: "inline-block",
                                            marginLeft: "7px",
                                            position: 'relative',
                                            top: '2px',
                                        }}
                                    />
                                )}
                            </button>
                        </div>
                    </div>
                </article>
            </form>

            {/* Restore_User_Popup */}
            <Popup
                className="restoreDepartmentModal deleteUserModal"
                open={flag !== ""}
                onClose={() => setFlag("")}
            >
                <div className="modal textCenter">
                    <p>
                        User exists with the same username. Do you want to restore it?
                    </p>
                    <div className="btnWrap">
                        <button
                            onClick={() => setFlag("")}
                            type="button"
                            className="btn btnSecondary"
                        >
                            Cancel
                        </button>
                        <button
                            onClick={restorUserEntity}
                            type="button"
                            className="btn btnPrimary"
                            disabled={loader}
                        >
                            Restore
                            {loader && (
                                <HalfCircleSpinner
                                    size={18}
                                    style={{
                                        display: "inline-block",
                                        marginLeft: "7px",
                                        position: "relative",
                                        top: "2px",
                                    }}
                                />
                            )}
                        </button>
                    </div>
                </div>
            </Popup>
        </>
    )
}

export default CreateUser;