import React, { useState, useEffect } from "react";
import { useInput } from '../../hooks/useInput';
import updateUser from "../../api/services/updateUserInfo";
import { toast } from 'react-toastify';
import userListing from "../../api/services/userListing";
import Select from 'react-select';
import { rolesListing } from "../../api/services/user-services/role/index";
import { positionListingAll } from "../../api/services/positions/index";
import { HalfCircleSpinner } from "react-epic-spinners";
import { useAllDepartments } from "../../context/allDepartmentContext";
import { departmentGroups, departmentListingAll } from "../../api/services/departmnent-services";
import { recreateUser } from "../../api/services/departmnent-services/recreate";
import Popup from "reactjs-popup";

const UpdateInfo = (props) => {
    const [errors, setErrors] = useState({});
    const [password, bindPassword, resetPassword] = useInput("");
    const [confirmPassword, bindConfirmPassword, resetConfirmPassword] = useInput("");
    const [username, bindUsername] = useInput(props.selectedUser.username ?? '');
    const [salutationName, setSalutationName] = useState(props.selectedUser.salutation ?? '');
    const [firstname, bindFirstname] = useInput(props.selectedUser.first_name ?? '');
    const [surname, bindSurname] = useInput(props.selectedUser.surname ?? '');
    const [email, bindEmail] = useInput(props.selectedUser.email ?? '');
    const [phoneNumber, setPhoneNumber] = useState(props.selectedUser.phone_number ?? '');
    const [selectedImage, setSelectedImage] = useState(props.selectedUser.image ?? null);
    const [imageUpdload, setImageUpdload] = useState(null);
    const [positionListing, setPositionListing] = useState([]);
    const [activePosition, setActivePosition] = useState(props.selectedUser.position_id ?? '');
    const [status, setStatus] = useState(props.selectedUser.status);
    const [userType, setUserType] = useState(props.selectedUser.type);
    const [department, setDepartment] = useState(props.selectedUser.department_id ?? '');
    const [group, setGroup] = useState((props.selectedUser.groups && (Object.keys(props.selectedUser.groups)?.length > 0)) ? Object.entries(props.selectedUser.groups).map(([key, value]) => ({ label: value, value: parseInt(key) })) : []);
    const [departmentList, setDepartmentList] = useState([])
    const [loader, setLoader] = useState(false);
    const [flag, setFlag] = useState("");
    const [groupListing, setGroupListing] = useState([]);
    const [showLoader, setShowLoader] = useState(false);
    const [showPass, setShowPass] = useState(false);
    const [showConfirmPass, setShowConfirmPass] = useState(false);

    // Fetch_Selected_Department_Entities_Listing
    const fetchDepartmentEntitiesList = (id) => {
        departmentGroups.get(id)
            .then((res) => {
                if (res.data?.length) {
                    const transformedGroupData = res.data.map(item => ({
                        value: item.id,
                        label: item.name,
                    }));
                    setGroupListing(transformedGroupData);
                }
            }
            ).catch(error => {
                console.error('Error fetching data:', error);
            }
            );
    }

    // Fetch_Positions_Listing_onload
    useEffect(() => {
        let positionsList = []

        positionListingAll.get()
            .then((res) => {
                positionsList = res.data;
                positionsList.map((e) => {
                    if (e.id === activePosition) {
                        setActivePosition(e.id)
                    }
                })
                if (positionsList.some(p => p.id !== props.selectedUser.position_id)) {
                    setPositionListing([...res.data, { id: props.selectedUser.position_id, name: props.selectedUser.position_name, deleted: true }]);
                    setActivePosition(props.selectedUser.position_id ?? '')
                } else {
                    setPositionListing(res.data)
                }
            }
            ).catch(error => console.error('Error fetching data:', error));

        departmentListingAll.list().then((res) => {
            if (res.data && res.data && res.data.length > 0) {
                const dep = res.data.find(p => p.id !== props.selectedUser.department_id)
                if (dep) {
                    setDepartmentList([...res.data, { id: props.selectedUser.department_id, name: props.selectedUser.department_name, deleted: true }]);
                    setDepartment(props.selectedUser.department_id ?? '')
                } else {
                    setDepartmentList(res.data)
                }
            }
        }).catch(error => console.error('Error fetching data:', error));
        fetchDepartmentEntitiesList(props.selectedUser.department_id)

    }, [props.selectedUser]);

    // Handle_Position_Select
    const handlePositionSelect = (event) => {
        setPositionListing([...positionListing.filter(item => !item.deleted)])
        setActivePosition(event.target.value);
    };

    const checkValidation = () => {
        let errors = {};
        let isValid = true;
        if (!username) {
            isValid = false;
            errors['username'] = 'Please enter your Username.';
        }
        if (!department) {
            isValid = false;
            errors['department'] = 'Please select a Department.';
        }
        if (!group || (group?.length < 1)) {
            isValid = false;
            errors['group'] = 'Please select a group.';
        }
        if (!firstname) {
            isValid = false;
            errors['firstname'] = 'Please enter your Firstname.';
        }
        if (!salutationName) {
            isValid = false;
            errors['salutationName'] = 'Please select a Salutation.';
        }
        if (!activePosition) {
            isValid = false;
            errors['position'] = 'Please select a Position.';
        }
        if (!surname) {
            isValid = false;
            errors['surname'] = 'Please enter your Surname.';
        }
        if (!group || (group?.length < 1)) {
            isValid = false;
            errors['group'] = 'Please select a group.';
        }
        if (!phoneNumber) {
            isValid = false;
            errors['number'] = 'Please enter your Number.';
        }
        if (!email) {
            isValid = false;
            errors['email'] = 'Please enter your Email.';
        }
        if (!userType) {
            isValid = false;
            errors['userType'] = 'Please select user type.';
        }
        // if (!password) {
        //     isValid = false;
        //     errors["password"] = "Please enter password.";
        // }
        // if (!confirmPassword) {
        //     isValid = false;
        //     errors["confirmPassword"] = "Please enter Confirm Password.";
        // }
        // if (password !== confirmPassword) {
        //     isValid = false;
        //     errors["confirmPassword"] =
        //         "Password and Confirm Password should be same.";
        //     toast.error("Password and Confirm Password should be same.");
        // } else if (password && password?.length < 8) {
        //     isValid = false;
        //     toast.error("Pasword length should be atleast 8 chracters");
        // }
        // if (email) {
        //     var pattern = new RegExp(
        //         /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
        //     );
        //     if (!pattern.test(email)) {
        //         isValid = false;
        //         errors["email"] = "Please enter valid email address.";
        //     }
        // }
        setErrors(errors);
        return isValid;
    };

    useEffect(() => {
        setSelectedImage(props.selectedUser.image ?? null)
        setStatus(props.selectedUser.status)
        setActivePosition(props.selectedUser.position_id ?? '')
        setDepartment(props.selectedUser.department_id ?? '')
        setPhoneNumber(props.selectedUser.phone_number ?? '')
        setUserType(props.selectedUser.type ?? '')
        setSalutationName(props.selectedUser.salutation ?? '')
        setGroup((props.selectedUser.groups && (Object.keys(props.selectedUser.groups)?.length > 0))
            ? Object.entries(props.selectedUser.groups).map(([key, value]) => ({ label: value, value: parseInt(key) }))
            : [])
        resetPassword('')
        resetConfirmPassword('')
    }, [props.selectedUser])


    const handleUserTypeSelect = (event) => {
        setUserType(event.target.value);
    };

    // Handle_File_Upload
    const handleFileChange = (e) => {
        const file = e.target.files[0];

        if (file) {
            const fileSizeLimit = 3 * 1024 * 1024; // 5 MB
            const allowedFileTypes = ['image/jpeg', 'image/png', 'image/svg+xml'];

            if (file.size > fileSizeLimit) {
                alert('File size exceeds the limit of 3MB');
                return;
            }

            if (!allowedFileTypes.includes(file.type)) {
                alert('Invalid file type. Please select a JPG, PNG, or SVG file.');
                return;
            }
            setImageUpdload(file);

            setSelectedImage(URL.createObjectURL(file));
        }
    };


    // Handle_Update_User
    const handleUpdateUser = (event) => {
        event.preventDefault();
        const positionToSent = positionListing.find(p => (p.id === activePosition))
        const departmentToSent = departmentList.find(p => (p.id === department))
        if (checkValidation()) {
            setShowLoader(true);
            const formData = new FormData();
            formData.append('username', username);
            formData.append('first_name', firstname);
            formData.append('salutation', salutationName);
            formData.append('surname', surname);
            formData.append('email', email);
            !positionToSent?.deleted && formData.append("position_id", activePosition);
            formData.append('phone_number', phoneNumber);
            imageUpdload && formData.append("image", imageUpdload);
            !departmentToSent?.deleted && formData.append('department_id', department);
            group.forEach((group, index) => {
                formData.append(`group_ids_update[${index}]`, group.value);
            });
            password && formData.append('password', password);
            password && formData.append('confirm_password', confirmPassword);
            formData.append('status', status);
            formData.append('type', userType);

            updateUser.updateInfo(props.selectedUser.id, formData)
                .then((res) => {
                    if (res.status === 200) {
                        props.handleShowUpdateUser()
                        toast.success(res.message);
                        setShowLoader(false)
                        // window.location.reload();
                        userListing.getUsers(1)
                            .then((res) => {
                                props.setUsersList(res.data);
                                props.setPagination(res.meta);
                            }
                            ).catch(error => toast.error("Error While Fetching The User List"));
                    }
                })
                .catch((error) => {
                    setShowLoader(false)
                    if (error?.data?.errors?.id) {
                        setFlag(error.data.message);
                    } else {
                        toast.error(error?.message || error?.data?.message);
                    }
                });
        }
    };

    // Handle_Status_Select
    const handleStatusSelect = (event) => {
        setStatus(event.target.value);
    };

    // Handle_Department_Select
    const handleDepartmentSelect = (event) => {
        setDepartmentList([...departmentList.filter(item => !item.deleted)])
        setGroup([])
        const selectedDepartmentId = event.target.value;
        setDepartment(selectedDepartmentId);

        if (event.target.value) {
            fetchDepartmentEntitiesList(event.target.value);
        }
    };
    // Handle_Group_Select
    const handleGroupSelect = (event) => {
        setGroup(event);
    };

    // Restore_User
    const restorUserEntity = () => {
        setLoader(true);
        recreateUser
            .recreate(flag)
            .then((res) => {
                if (res.status === 200) {
                    toast.success(res.message);
                    setLoader(false);
                    props.handleShowUpdateUser()
                    userListing.getUsers(1)
                        .then((res) => {
                            props.setUsersList(res.data);
                            props.setPagination(res.meta);
                        }
                        ).catch(error => toast.error("Error While Fetching The User List"));
                }
            })
            .catch((error) => {
                setLoader(false);
                toast.error(error.data.message);
            });
    };

    const handleSalutationName = (event) => {
        setSalutationName(event.target.value);
    };

    // MultiSelect_Style_Object
    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            boxShadow: 'none',
            borderColor: state.isFocused ? '#aaa' : provided.borderColor,
            height: '30px',
            cursor: 'pointer',
            paddingRight: '24px',
            borderRadius: '0',
        }),
        option: (styles) => {
            return {
                ...styles,
                fontSize: "14px",
                transition: "all 0.35s ease",
                cursor: 'pointer',
                textAlign: 'left'
            };
        },
        indicatorsContainer: (provided) => ({
            ...provided,
            display: 'none',
        }),
        placeholder: (styles) => ({
            ...styles,
            color: 'var(--black)',
            fontSize: "14px",
        }),
    };

    // Handle_Show_Password
    const handleShowPass = () => {
        setShowPass(!showPass);
    }
    // Handle_Show_Confirm_Password
    const handleShowConfirmPass = () => {
        setShowConfirmPass(!showConfirmPass);
    }

    const uniqueDepIds = new Set();
    const uniquePosIds = new Set();


    return (
        <>
            <form className="loginForm userForms updateUserForm" onSubmit={(event) => handleUpdateUser(event)}>
                <article className="widgetWrap">
                    <div className="widgetBody">
                        <div className="dbRow">
                            <div className="dbCol dbColLg75">
                                <div className="dbRow">
                                    <div className="dbCol dbColLg50">
                                        <div className="formRow">
                                            <label className="txtLabel">Username</label>
                                            <div className="inputWrap">
                                                <input
                                                    type="text"
                                                    className={`formControl ${errors.username && 'error'}`}
                                                    {...bindUsername}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="dbCol dbColLg50">
                                        <div className="formRow">
                                            <label className="txtLabel">Type</label>
                                            <div className="selectWrap posRelative">
                                                <select
                                                    name="type"
                                                    className={`formControl ${errors.userType && 'error'}`}
                                                    id="type"
                                                    value={userType}
                                                    onChange={handleUserTypeSelect}
                                                >
                                                    <option value="" selected disabled>Select User Type</option>
                                                    <option value='assistant'>Editor </option>
                                                    <option value='consultant'>Reviewer </option>
                                                    <option value='hod'>Clinical Oversight</option>
                                                    <option value='super-user'>Super Admin</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="dbCol dbColLg50">
                                        <div className="formRow">
                                            <label className="txtLabel">Salutation</label>
                                            <div className="selectWrap posRelative">
                                                <select
                                                    name="type"
                                                    className={`formControl ${errors.salutationName && 'error'}`}
                                                    id="type"
                                                    value={salutationName}
                                                    onChange={handleSalutationName}
                                                >
                                                    <option value="" defaultValue>Select Salutation</option>
                                                    <option value='miss'>Miss </option>
                                                    <option value='mr'>Mr </option>
                                                    <option value='mrs'>Mrs</option>
                                                    <option value='ms'>Ms</option>
                                                    <option value='dr'>Dr</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="dbCol dbColLg50">
                                        <div className="formRow">
                                            <label className="txtLabel">First Name</label>
                                            <div className="inputWrap">
                                                <input
                                                    type="text"
                                                    className={`formControl ${errors.firstname && 'error'}`}
                                                    {...bindFirstname}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="dbCol dbColLg50">
                                        <div className="formRow">
                                            <label className="txtLabel">Surname</label>
                                            <div className="inputWrap">
                                                <input
                                                    type="text"
                                                    className={`formControl ${errors.surname && 'error'}`}
                                                    {...bindSurname}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="dbCol dbColLg50">
                                        <div className="formRow">
                                            <label className="txtLabel">Email</label>
                                            <div className="inputWrap">
                                                <input
                                                    type="email"
                                                    className={`formControl ${errors.email && 'error'}`}
                                                    {...bindEmail}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="dbCol dbColLg50">
                                        <div className="formRow">
                                            <label className="txtLabel">Contact Info</label>
                                            <div className="inputWrap">
                                                <input
                                                    type="text"
                                                    className={`formControl ${errors.number && 'error'}`}
                                                    value={phoneNumber}
                                                    onChange={(e) => {
                                                        const inputNumber = e.target.value;
                                                        if (/^[0-9]*$/.test(inputNumber)) {
                                                            setPhoneNumber(inputNumber);
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="dbCol dbColLg50">
                                        <div className="formRow">
                                            <label className="txtLabel">Department</label>
                                            <div className="selectWrap posRelative">
                                                <select
                                                    name="deparrment"
                                                    className={`formControl ${errors.department && 'error'}`}
                                                    id="department"
                                                    value={department}
                                                    onChange={handleDepartmentSelect}
                                                >
                                                    <option value="">Select Department</option>
                                                    {departmentList.filter(item => item.id).map((item) => {
                                                        if (!uniqueDepIds.has(item.id)) {
                                                            uniqueDepIds.add(item.id);
                                                            return (
                                                                <option key={item.id} value={item.id}>
                                                                    {item.name}
                                                                </option>
                                                            );
                                                        }
                                                        return null;
                                                    })}
                                                </select>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="dbCol dbColLg50">
                                        <div className="formRow">
                                            <label className="txtLabel">Group</label>
                                            <div className="selectWrap posRelative">
                                                <Select
                                                    name="group"
                                                    className={`basic-multi-select multiSelectDropdown txtRight ${errors.group && 'error'}`}
                                                    id="group"
                                                    value={group}
                                                    options={groupListing}
                                                    onChange={(event) => {
                                                        handleGroupSelect(event)
                                                    }}
                                                    classNamePrefix="select"
                                                    isMulti
                                                    styles={customStyles}
                                                    placeholder="Select Groups"
                                                />
                                                {/* <Select
                                                name="department"
                                                className={`basic-multi-select`}
                                                id="department"
                                                value={department}
                                                options={transformedDepartmentData}
                                                onChange={(event) => {
                                                    handleDepartmentSelect(event)
                                                }
                                                }
                                                classNamePrefix="select"
                                                isMulti
                                            /> */}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="dbCol dbColLg50">
                                        <div className="formRow">
                                            <label className="txtLabel">Position</label>
                                            <div className="selectWrap posRelative">
                                                <select
                                                    name="position"
                                                    className={`formControl ${errors.position && 'error'}`}
                                                    id="position"
                                                    value={activePosition}
                                                    onChange={handlePositionSelect}
                                                >
                                                    <option value="">Select Position</option>

                                                    {positionListing.filter(item => item.id).map((item) => {
                                                        if (!uniquePosIds.has(item.id)) {
                                                            uniquePosIds.add(item.id);
                                                            return (
                                                                <option key={item.id} value={item.id}>
                                                                    {item.name}
                                                                </option>
                                                            );
                                                        }
                                                        return null;
                                                    })}
                                                </select>
                                            </div>
                                        </div>
                                    </div>



                                    {/* <div className="dbCol dbColLg50">
                                    <div className="formRow">
                                        <label className="txtLabel">Role</label>
                                        <div className="selectWrap posRelative">
                                            <select
                                                name="role"
                                                className={`formControl ${errors.role && 'error'}`}
                                                id="role"
                                                value={activeRole}
                                                onChange={handleRoleSelect}
                                            >
                                                <option value="placeholder" selected disabled>Select Role</option>
                                                {roleListing.map((item) => (
                                                    <option key={item.id} value={item.id}>
                                                        {item.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                </div> */}

                                    <div className="dbCol dbColLg50">
                                        <div className="formRow">
                                            <label className="txtLabel">Status</label>
                                            <div className="selectWrap posRelative">
                                                <select
                                                    name="status"
                                                    className={`formControl`}
                                                    id="status"
                                                    value={status}
                                                    onChange={handleStatusSelect}
                                                >
                                                    <option value="1" selected>Active</option>
                                                    <option value="0" selected>Inactive</option>

                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="dbCol dbColLg50">
                                        <div className="formRow">
                                            <label className="txtLabel"></label>
                                            <div className="inputWrap">
                                                <label htmlFor="profileImage" className="imageUploadBtn">Upload Image</label>
                                                <input
                                                    type="file"
                                                    name="profile-image"
                                                    id="profileImage"
                                                    onChange={handleFileChange}
                                                    className="srOnly"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="dbCol dbColLg50">
                                        <div className="formRow">
                                            <label className="txtLabel">Password</label>
                                            <div className={`inputWrap posRelative passWrap ${showPass && 'view'}`}>
                                                <input
                                                    placeholder="*******"
                                                    type={showPass ? 'text' : 'password'}
                                                    className={`formControl ${errors.password && "error"}`}
                                                    id="passwordInput"
                                                    {...bindPassword}
                                                />
                                                <button
                                                    type='button'
                                                    className={`icnView fa-solid posAbsolute ${showPass ? 'fa-eye-slash' : 'fa-eye'}`}
                                                    onClick={() => {
                                                        handleShowPass()
                                                    }}
                                                ></button>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="dbCol dbColLg50">
                                        <div className="formRow">
                                            <label className="txtLabel">Confirm Password</label>
                                            <div className={`inputWrap posRelative passWrap ${showConfirmPass && 'view'}`}>
                                                <input
                                                    placeholder="*******"
                                                    id="passwordInput"
                                                    type={showConfirmPass ? 'text' : 'password'}
                                                    className={`formControl ${errors.confirmPassword && "error"
                                                        }`}
                                                    {...bindConfirmPassword}
                                                />
                                                <button
                                                    type='button'
                                                    className={`icnView fa-solid posAbsolute ${showConfirmPass ? 'fa-eye-slash' : 'fa-eye'}`}
                                                    onClick={() => {
                                                        handleShowConfirmPass()
                                                    }}
                                                ></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="dbCol dbColLg25">
                                <div className="imgWrap selectedImage">
                                    {selectedImage ?
                                        <img src={selectedImage} alt="Uploaded Image" className="uploaded-image" />
                                        :
                                        <div className="placeholderImage">?</div>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="btnWrap">
                            <button
                                onClick={() => { props.handleShowUpdateUser() }}
                                type="button"
                                className="btn btnWhite"
                            >Cancel</button>
                            <button
                                type="submit"
                                className="btn btnPrimary mb0"
                                disabled={showLoader}
                            >Update User {showLoader && (
                                <HalfCircleSpinner
                                    size={18}
                                    style={{
                                        display: "inline-block",
                                        marginLeft: "7px",
                                        position: 'relative',
                                        top: '2px',
                                    }}
                                />
                            )}</button>
                        </div>
                    </div>
                </article>
            </form>

            {/* Restore_User_Popup */}
            <Popup
                className="restoreDepartmentModal deleteUserModal"
                open={flag !== ""}
                onClose={() => setFlag("")}
            >
                <div className="modal textCenter">
                    <p>
                        User exists with the same username. Do you want to restore it?
                    </p>
                    <div className="btnWrap">
                        <button
                            onClick={() => setFlag("")}
                            type="button"
                            className="btn btnSecondary"
                        >
                            Cancel
                        </button>
                        <button
                            onClick={restorUserEntity}
                            type="button"
                            className="btn btnPrimary"
                            disabled={loader}
                        >
                            Restore
                            {loader && (
                                <HalfCircleSpinner
                                    size={18}
                                    style={{
                                        display: "inline-block",
                                        marginLeft: "7px",
                                        position: "relative",
                                        top: "2px",
                                    }}
                                />
                            )}
                        </button>
                    </div>
                </div>
            </Popup>
        </>
    )
}

export default UpdateInfo;